import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MandatFormService {

  constructor() {
  }

  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  personalInformationObservable = new Subject();
  personalInformation: any;
  accountManager: any;
  contractList: any;
  accountId: any;
  preSavedData = new Subject();

  clearData(){
    this.personalInformationObservable.next('');
    this.personalInformation = '';
    this.accountManager = '';
    this.contractList = '';
    this.accountId = '';
  }

  setPersonalInformation(data: any) {
    const formData = {
      "first_name": data.value.firstName,
      "last_name": data.value.lastName,
      "street": data.value.street,
      "post_code": data.value.postCode,
      "city": data.value.city,
      "email": data.value.email,
      "phone": data.value.phone,
    }

    this.personalInformationObservable.next(formData);
    this.personalInformation = formData;
  }

  setRegisteredAccountManager(data: any, company: any) {
    const formData = {
      "company": company.address.company_name,
      "street": company.address.street,
      "post_code": company.address.post_code,
      "city": company.address.city,
      "user": data.value.id,
      "first_name": data.value.first_name,
      "last_name": data.value.last_name,
      "email": data.value.email,
      "phone": data.value.phone,
    }

    this.accountManager = formData;
  }

  setUnregisteredAccountManager(data: any) {
    const formData = {
      "company": data.value.companyName,
      "street": data.value.street,
      "post_code": data.value.postCode,
      "city": data.value.city,
      "first_name": data.value.firstName,
      "last_name": data.value.lastName,
      "email": data.value.email,
      "phone": data.value.phone,
    }

    this.accountManager = formData;
  }
}
