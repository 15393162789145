<div class="container" *ngIf="user">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">{{ user.first_name }} {{ user.last_name }}</h1>
      <h2 class="col-lg-9 pb-sm">
        Du bist den nachfolgenden Accounts zugewiesen. Um deren übermittelten Mandate einzusehen musst Du auf den
        entsprechenden Account klicken.
      </h2>

      <h2 class="col-lg-9 pb-sm" *ngIf="brokerAccount">
        Ist der Account mit «Broker» gekennzeichnet, werden die Mandate aller Subbroker miteinbezogen.
      </h2>

      <!-- Account Liste -->
      <mat-chip-listbox selectable aria-label="Account selection">
        <mat-chip-option *ngFor="let account of accounts"
                         (click)="getMandateList(account.id, 1)">
          {{ account.name}}
        </mat-chip-option>
        <mat-chip-option *ngIf="brokerAccount"
                         (click)="getBrokerList(brokerAccount.id, 1)">
          {{ brokerAccount.name }}
        </mat-chip-option>
      </mat-chip-listbox>

      <!-- Accordion -->
      <div *ngIf="submissions && isVisible" class="pt-md">
        <mat-accordion class="expansion-panel-headers-align">
          <mat-expansion-panel *ngFor="let submission of submissions">

            <!-- Expansion Panel Header -->
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>{{ submission.mandate_client.first_name }} {{ submission.mandate_client.last_name }}</h3>
              </mat-panel-title>
              <mat-panel-description>
                <span> {{submission.pub_date | date: 'dd. MMM. yyyy HH:mm'}} </span>
                <mat-icon *ngIf="submission.pdf_files.length === 0" class="material-symbols-outlined">unpublished</mat-icon>
                <mat-icon *ngIf="submission.pdf_files.length > 0" class="material-symbols-outlined">check_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">

              <!-- Kunde -->
              <div class="col-md">
                <h3 class="pb-xsm">Kunde:</h3>
                <mat-divider></mat-divider>
                <div class="pt-xsm">
                  {{ submission.mandate_client.first_name }} {{ submission.mandate_client.last_name }}<br>
                  {{ submission.mandate_client.street }}<br>
                  {{ submission.mandate_client.post_code }} {{ submission.mandate_client.city }}<br>
                  {{ submission.mandate_client.phone }}<br>
                  {{ submission.mandate_client.email }}
                </div>
              </div>

              <!-- Kundenberater -->
              <div class="col-md">
                <h3 class="pb-xsm">Kundenberater:</h3>
                <mat-divider></mat-divider>
                <div class="pt-xsm">
                  {{ submission.mandate_manager.first_name }} {{ submission.mandate_manager.last_name }}<br>
                  {{ submission.mandate_manager.street }}<br>
                  {{ submission.mandate_manager.post_code }} {{ submission.mandate_manager.city }}<br>
                  {{ submission.mandate_manager.email }}<br>
                </div>
              </div>

              <!-- Policen -->
              <div class="col-md">
                <h3 class="pb-xsm">Policen:</h3>
                <mat-divider></mat-divider>
                <div class="pt-xsm">
                  <ng-container *ngIf="submission.police.length > 0">
                    <div *ngFor="let police of submission.police" class="pt-xsm">
                      {{ police.company }} {{ police.contract }}: {{ police.police }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="submission.police.length == 0">
                    <div>Es wurden keine Policen übermittelt</div>
                  </ng-container>
                </div>
              </div>

              <!-- Dokumente -->
              <div class="col col-md" *ngIf="submission.pdf_files.length > 0">
                <h3 class="pb-xsm">Dokumente</h3>
                <mat-divider></mat-divider>
                <div *ngFor="let pdf of submission.pdf_files" class="pt-xsm">
                  <a (click)="openPdf(pdf.pdf_file)">
                    <mat-icon style="display: inline-block; vertical-align: middle" class="material-symbols-outlined">picture_as_pdf</mat-icon>
                    <span style="padding-left: 12px">{{ pdf.name }}</span>
                  </a>
                </div>
              </div>

            </div>
            <div class="row pt-lg">
              <!-- Send Email to Customer -->
              <div class="col" *ngIf="submission.pdf_files.length === 0">
                <button mat-fab
                        extended
                        color="primary"
                        (click)="sendLink(submission)">
                  <mat-icon class="material-symbols-outlined">draw</mat-icon>
                  Link zum signieren an Kunde senden
                </button>
              </div>
            </div>
            <mat-progress-bar mode="indeterminate"
                              style="top:0 !important; left:0 !important;; height: 4px !important; opacity: 1 !important;"
                              *ngIf="isLoading"></mat-progress-bar>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- Paginator -->
        <mat-paginator *ngIf="submissions.length > 0 && !isBrokerAccount"
                       [length]="totalSubmissions"
                       [pageSize]="pageSize"
                       (page)="getMandateList(accountId, $event.pageIndex + 1)">
        </mat-paginator>
        <!-- Paginator -->
        <mat-paginator *ngIf="submissions.length > 0 && isBrokerAccount"
                       [length]="totalSubmissions"
                       [pageSize]="pageSize"
                       (page)="getBrokerList(brokerAccount.id, $event.pageIndex + 1)">
        </mat-paginator>
        <div *ngIf="submissions.length == 0">
          <h3 class="col-lg-9 pb-sm">
            Für diesen Account wurden noch keine Mandate übermittelt.
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
