import {Component} from '@angular/core';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  standalone: true,
  imports: []
})
export class ImpressumComponent {

}
