import {Component} from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss'],
  standalone: true,
  imports: []
})
export class DatenschutzComponent {

}
