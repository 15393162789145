<div class="row pt-xl">
  <div class="col">
    <h1 class="pb-sm">Dokument unterzeichnen</h1>
    <h2 class="col-lg-9 pb-sm">
      Um das Dokument zu unterschreiben, klicken Sie auf die Schaltfläche «Maklermandat unterschreiben». Ihre
      Unterschrift wird an der richtigen Stelle zusammen mit dem aktuellen Datum unter «Unterschrift Mandant» eingefügt.
      Das Maklermandat kann jederzeit wieder aufgelöst werden.
      <div class="row pt-sm">
        <div class="col">
          <button mat-fab
                  extended
                  color="primary"
                  (click)="openDialog()">
            <mat-icon class="material-symbols-outlined">draw</mat-icon>
            Maklermandat unterzeichnen
          </button>
        </div>
      </div>
    </h2>
  </div>
</div>


<div class="row pt-xl" *ngIf="img">
  <h2 class="pb-sm">Dokumente</h2>
  <div class="col">
    <a (click)="viewPdf(1)">
      <span class="material-symbols-outlined" style="vertical-align: middle;">picture_as_pdf</span>
      <h3 style="display: inline-block; vertical-align: middle; margin-left: 12px; width: 80%;">
        Maklermandat für die Verwaltung des Versicherungsportefeuilles
      </h3>
    </a>
    <br>
    <a (click)="viewPdf(2)" *ngIf="mobiPdf">
      <span class="material-symbols-outlined" style="vertical-align: middle;">picture_as_pdf</span>
      <h3 style="display: inline-block; vertical-align: middle; margin-left: 12px; width: 80%;">
        Verwaltung der Versicherungsverträge der Mobiliar
      </h3>
    </a>
    <br><br>
    <a (click)="viewPdf(3)">
      <span class="material-symbols-outlined" style="vertical-align: middle;">picture_as_pdf</span>
      <h3 style="display: inline-block; vertical-align: middle; margin-left: 12px; width: 80%;">
        Allgemeine Geschäftsbedingungen
      </h3>
    </a>
  </div>
</div>

<div class="pdf-wrapper" style="display: none;">
  <div id="pdf-content" class="pdf-content">
    <div class="container">
      <img src="/assets/images/logo/bsc-logo.png" class="bsc-logo" alt="">
      <div class="row">
        <div class="col">
          <h2>
            Maklermandat / Vollmacht und Auftrag<br>
            für die Verwaltung des Versicherungsportefeuilles
          </h2>
        </div>
      </div>

      <div class="row" *ngIf="client">
        <div class="col-3">
          Zwischen
        </div>
        <div class="col">
          {{ client.first_name }} {{ client.last_name }}<br>
          {{ client.street }}, {{ client.post_code }} {{ client.city }}<br>
          nachstehend Mandant genannt
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          und der
        </div>
        <div class="col">
          BSC Broker Service Center GmbH<br>
          Picardiestrasse 3A, 5040 Schöftland<br>
          nachstehend BSC genannt
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          in Zusammenarbeit mit dem Broker
        </div>
        <div class="col">
          Life Gate Consulting AG<br>
          Lindenhofweg 4, 8806 Bäch<br>
          nachstehend Broker genannt
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Der Mandant erteilt dem Broker die Vollmacht und den Auftrag,
            die bestehenden Versicherungspolicen über die BSC zu verwalten.
            Die Vereinbarung zur Verwaltung des Versicherungsportefeuilles umfasst im Weiteren folgende
            Dienstleistungen:
          </p>
          <ul>
            <li>Betreuung von bestehenden Versicherungsverträgen</li>
            <li>Periodische Prüfung des Prämienangebotes auf dem Versicherungsmarkt</li>
            <li>Einholen von Offerten bei verschiedenen Versicherungsgesellschaften</li>
            <li>bernahme von Erneuerungen und Neuabschlüssen</li>
          </ul>
          <p>
            Der Mandant bleibt Versicherungsnehmer und Prämienschuldner. Er unterzeichnet Versicherungsanträge selbst
            und
            nimmt Schadenzahlungen direkt entgegen. Der Mandant beauftragt die Gesellschaften, sämtliche Verträge und
            Bestände
            auf
            die BSC zu übertragen. Der Broker vertritt den Mandanten in allen übrigen Belangen gegenüber den
            Versicherungsgesellschaften.
          </p>
          <p>
            Folgende Akten sind von den Gesellschaften zur Verarbeitung an die BSC zu senden:
          </p>
          <ul>
            <li>Policen, Nachträge, Vertragsänderungen, Prämienrechnungen, Mahnungen, Kopien der übrigen Korrespondenz
            </li>
            <li>Offerten für die Mandanten sowie die übrige Korrespondenz</li>
          </ul>
          <p>
            Der Mandant wünscht ausdrücklich von den Aussendiensten der Gesellschaften nicht mehr besucht zu werden. Der
            Broker kann im Namen des Mandanten Verhandlungen führen sowie die erforderlichen Abklärungen und
            Vorkehrungen zur Vertragsgestaltung treffen.
          </p>
          <p>
            Allfällige Veränderungen der Tatbestände, welche auf die Versicherungsdeckung Einfluss haben, sind vom
            Mandanten umgehend an den Broker zu melden, damit die Versicherungsdeckung neu überprüft und angepasst
            werden kann
            (z.B. Tätigkeitsänderung oder Dienstleistungserweiterungen bei Firmen, etc.). Wird der Broker nicht über die
            eingetretenen Veränderungen informiert, ist dieser bei Versicherungsschäden für die fehlende Deckung nicht
            haftbar. Die Haftung der BSC wird gemäss AGB Art. 3 ausgeschlossen.
          </p>
          <p>
            Diese Vereinbarung tritt mit der Unterzeichnung durch alle drei Parteien auf unbestimmte Zeit in Kraft und
            gilt bis auf deren Widerruf. Die unterzeichneten Parteien bestätigen, mit den Regelungen dieses
            Maklermandates
            einverstanden zu sein. Der Mandant bestätigt im Weiteren mit seiner Unterschrift, die Allgemeinen
            Geschäftsbedingungen des Brokers (AGB) erhalten und zur Kenntnis genommen zu haben (VAG Art. 45) und über
            die Entschädigungsmodalitäten (AGB Art. 5) informiert worden zu sein.
          </p>
          <p>
            Dieses Maklermandat hat ab dem Datum der Unterzeichnung volle Gültigkeit und kann jederzeit aufgelöst
            werden.
          </p>
        </div>
        <div class="row row-signature">
          <span *ngIf="client && img" style="margin-top: -24px; margin-left: -11px">
            {{ client.city }} {{ currentDateString }}
          </span>
          <div class="col">
            Ort und Datum
          </div>
          <div class="col">
            <img src="{{ img }}" class="canvas-signature" alt=""/>
            Unterschrift Mandant
          </div>
        </div>
        <div class="row row-signature">
          <div class="col">
            Ort und Datum
          </div>
          <div class="col">
            Unterschrift Broker
          </div>
        </div>
        <div class="row row-signature">
          <div class="col">
            Ort und Datum
          </div>
          <div class="col">
            Unterschrift BSC Broker Service Center GmbH
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
