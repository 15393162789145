<div class="container">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Allgemeine Geschäftsbedingungen AGB</h1>
      <div class="col-lg-9">

        <h2 class="pb-sm">1. Zweck und Rechtsstellung der Gesellschaft</h2>
        <p class="pb-sm">Die Life Gate Consulting AG– nachfolgend Life Gate Consulting AG genannt - ist eine Aktiengesellschaft mit
          Sitz in Bäch SZ. Der Gesellschaftszweck besteht in der Erbringung von Dienstleistungen als Broker im
          Versicherungsbereich. Ein Auftrag für Mandanten der Life Gate Consulting wird mittels Maklermandat, welches
          durch alle Beteiligten unterzeichnet wird, begründet.
        </p>

        <h2 class="pb-sm">2. Informationspflichten an die Mandanten (gem. Art. 45 VAG)</h2>
        <ul class="pb-sm">
          <li>Der Berater weist sich gegenüber dem Mandanten aus. Dazu übergibt er dem Mandanten eine auf den Berater
            lautende Visitenkarte ab.
          </li>
          <li>Der Berater klärt den Mandanten darüber auf, ob die für einen Vertrag angebotenen Versicherungsdeckungen
            von
            einem oder mehreren Versicherungsunternehmen stammen und um welche Versicherungsunternehmen es sich
            handelt.
          </li>
          <li>Der Berater übergibt dem Mandanten vor Abschluss des Vertrages jeweils die Allgemeinen
            Versicherungsbedingungen, Zusatzbedingungen und Besondere Bedingungen zum entsprechenden Antrag.
          </li>
          <li>Die Life Gate Consulting AG bestätigt, mit folgenden Versicherungsgesellschaften Vertragsbeziehungen zu
            pflegen:
          </li>
        </ul>

        <p class="pb-sm">AIG, Allianz Suisse, Alvoso, Asga, Atupri, Automate, AXA, AXA Arag, AXA Art, Basler, CAP, Chubb, Concordia,
          Convitus, CSS, Dextra, Elips Life, Emmental, Epona, Europäische Reiseversicherung, Fortuna Rechtsschutz,
          Futura, Gastrosocial, Gemini, Generali, Groupe Mutuel, GVB, Helsana, Helvetia, Hotela, Innova, Liberty,
          Liechtenstein Life, Mannheimer, Mobiliar, Nest, NoventusCollect, OCC, Orion, ÖKK, PAT-BVG, Pax, PK Aetas, PKG,
          PK SHP, Previs, Profond, Pro Life, Pro Medico, Prosperita, Protekta, RMS, Schweizerische Ärztekrankenkasse,
          Skandia Leben, Smile direct, SSO, Solida, Stiftung Abendrot, Swica, Swisscanto, Swiss Life, Sympany, Tellco
          pkPRO, TSM, UWP, Vaudoise, Visana, VSAO, VVST, Zürich
          Die Life Gate Consulting AG ist den genannten Versicherungsgesellschaften weder wirtschaftlich noch rechtlich
          verpflichtet. Gemäss VAG gilt die Life Gate Consulting AG als ungebundener Versicherungsvermittler.</p>

        <h2 class="pb-sm">3. Haftung</h2>
        <p class="pb-sm">Entstehen aus der Tätigkeit als Versicherungsbroker Schadenersatzansprüche, haftet die Life Gate Consulting
          AG dafür (Berufshaftpflicht gemäss VAG). Die Haftung beschränkt sich auf Vorsatz und grobfahrlässige Handlung.
          Für Schäden aus entgangenem Gewinn haftet die Life Gate Consulting AG nicht. Wird ein Leistungsanspruch
          geltend gemacht, wird dieser nur akzeptiert, sofern der Mandant sämtliche Mitwirkungspflichten rechtzeitig
          erfüllt hat. Sind Unterlagen oder Informationen des Mandanten unvollständig oder mangelhaft und entsteht
          direkt oder indirekt daraus ein Schaden, haftet die Life Gate Consulting AG nicht dafür.
          Schadenersatzansprüche verjähren 6 Monate nach Bekanntwerden des Schadens. Endet die Vertragsbeziehung
          zwischen dem Mandanten und der Life Gate Consulting AG (z.B. durch
          Kündigung Maklermandat), endet auch der Haftungsanspruch gegenüber der Life Gate Consulting AG. </p>

        <p class="pb-sm">Dort, wo nicht das Versicherungsunternehmen für die Fehler, Nachlässigkeiten oder unrichtigen Auskünfte aus
          der Vermittlungstätigkeit der Life Gate Consulting AG haftet, hat die Life Gate Consulting AG eine
          Berufshaftpflicht bei der (Versicherungsgesellschaft bei der die Vermögensversicherung abgeschlossen wurde)
          über eine Summe von 2 Millionen Schweizer Franken abgeschlossen (gem. Art. 45 VAG).</p>
        <p class="pb-sm">Haftungsansprüche sind zu richten an:</p>
        <p class="pb-sm">Life Gate Consulting AG, (Mitarbeiter des Brokers), (Adresse der Brokerfirma inkl. Tel. Nr.)
          Die BSC Broker Service Center GmbH (BSC) erbringt Dienstleistungen für die Life Gate Consulting AG. Diese
          umfasst das Produktemarketing, erarbeiten von allgemeinen Vergleichen, die Offertkoordination, die Triage der
          Korrespondenz, die Provisionsabrechnung und weitere von uns delegierten Leistungen. Das Riskmanagement und die
          Betreuung der Mandanten obliegt alleine der Life Gate Consulting AG. Entstehen aus der Tätigkeit als
          Versicherungsbroker Schadenersatzansprüche, haftet alleine die Life Gate Consulting AG. Die BSC haftet
          gegenüber dem Mandanten nicht. Jegliche Haftungsansprüche gegenüber dem Mandanten sind wegbedungen. Mit der
          Unterschrift auf dem Maklermandat erklärt sich der Mandant als damit einverstanden.</p>

        <h2 class="pb-sm">4. Datenschutz / Geheimhaltung </h2>
        <p class="pb-sm">Die MitarbeiterInnen der BSC und der Life Gate Consulting AG unterliegen der Schweigepflicht. Die
          Datenbearbeitung kann verschiedenartig durchgeführt werden. Diese ist in der Datenschutzerklärung
          festgehalten. Die Datenschutzerklärung ist auf der Webseite der BSC (brokerservice.ch) und der Life Gate
          Consulting AG (webseite des co-Brokers.ch) abrufbar. Die Daten werden in Papierform und/oder elektronisch
          aufbewahrt. Der Mandant erklärt sich mit dieser Datenschutzerklärung einverstanden.</p>


        <h2 class="pb-sm">5. Entschädigung</h2>
        <h2 class="pb-sm">Honorar</h2>
        <p class="pb-sm">Der Mandant schuldet der Life Gate Consulting AG für vereinbarte oder in seinem Interesse erbrachten
          Dienstleistungen Honorare und Nebenkosten in absteigender Reihenfolge gemäss:</p>
        <ul class="pb-sm">
          <li>Individuell vereinbartem Honorar</li>
          <li>Preisliste der Life Gate Consulting AG
          </li>
          <li>Nach Aufwand zu einem Stundensatz von CHF 250.- exkl. MWST soweit nicht durch die Entschädigung Dritter
            gedeckt
          </li>
          <li>Ohne Abrechnung, das heisst, die Life Gate Consulting AG vereinnahmt die Entschädigung Dritter für die
            erbrachten Dienstleistungen ohne Recht des Mandanten auf Herausgabe der Entschädigung Dritter
          </li>
        </ul>
        <p class="pb-sm">Entschädigungsvereinbarungen zwischen dem Mandanten und der Life Gate Consulting AG betreffen die BSC Broker
          Service Center GmbH nicht. Ausgenommen davon sind durch die BSC Broker Service Center GmbH schriftlich
          bestätigte Änderungen.</p>
        <p class="pb-sm">Entschädigung Dritter</p>
        <p class="pb-sm">Der Mandant ist sich bewusst und akzeptiert, dass die Life Gate Consulting AG im Rahmen seiner Tätigkeit als
          Broker oder bei Gelegenheit der Auftragserfüllung Entschädigungen (z.B. Provisionen, Courtagen, usw.) von
          Dritten, insbesondere von Versicherungs-gesellschaften, erhält oder erhalten könnte. Falls die Life Gate
          Consulting AG solche Entschädigungen erhält, welche es gemäss jeweils aktueller Rechtsprechung oder gemäss
          jeweils anwendbaren gesetzlichen Vorschriften dem Mandanten abliefern müsste, so ist der Mandant ausdrücklich
          damit einverstanden, dass die Life Gate Consulting AG diese Entschädigung zusätzlich für seine Tätigkeit für
          den Mandanten erhält. Der Mandant erklärt mit der Unterzeichnung des Maklermandates ausdrücklich, auf die
          Herausgabe dieser
          Entschädigung zu verzichten.</p>
        <p class="pb-sm">Wird nichts anderes vereinbart, gilt die Abrechnung nach 5d. Wünscht der Mandant im Nachhinein eine andere
          Abrechnungsart als vereinbart, verzichtet der Mandant wie beschrieben auf eine rückwirkende Herausgabe der
          Entschädigung Dritter. In der Beilage zu den Allgemeinen Geschäftsbedingungen hat der Mandant eine Liste mit
          den ungefähren
          Entschädigungssätzen der Versicherungsgesellschaften bekommen. Dies zur Information und Transparenz. Mit
          dieser Liste ist dem Kunden bekannt, auf welche Entschädigungen er verzichtet.</p>

        <h2 class="pb-sm">6. Dienstleistungen</h2>
        <p class="pb-sm">Die Life Gate Consulting AG betreut und berät den Mandanten in Versicherungsangelegenheiten. Dies beinhaltet
          insbesondere die Betreuung aller bestehenden Versicherungsverträge, Überprüfung des Versicherungsbedarfs und
          des Versicherungsportefeuilles,
          periodische Prüfung des Prämienangebotes auf dem Versicherungsmarkt, Einholen von Offerten bei verschiedenen
          Versicherungsgesellschaften, Erneuerungen und Neuabschlüsse von Versicherungspolicen und Unterstützung im
          Schadenfall.
          Für weitergehende Auftragsarbeiten wird ein Beratungshonorar gemäss gegenseitiger Absprache verrechnet.</p>

        <h2 class="pb-sm">7. Mandantenangaben / Legitimationsprüfung</h2>
        <p class="pb-sm">Der Mandant verpflichtet sich, bei der Aufnahme eines Versicherungsantrages alle Informationen betreffend den
          Personen- und Sachinformationen wahrheitsgetreu an die Life Gate Consulting AG an- resp. weiterzugeben.
          Insbesondere ist die Korrektheit der Mandantenaussagen bei Gesundheitsfragen unumgänglich. Werden Tatbestände
          oder Krankheiten verschwiegen, kann dies zu einer Anzeigepflichtverletzung führen. Dies hat zur Folge, dass
          die Versicherungsgesellschaft im Schadenfall keine oder verminderte Leistungen erbringt und per sofort vom
          Vertrag zurücktritt. Die Life Gate Consulting AG verpflichtet sich zur gewissenhaften Prüfung der Legitimation
          des Kunden und der Bevollmächtigten. Den aus dem Nichterkennen von Legitimationsmängeln und Fälschungen oder
          Täuschungen entstandenen Schaden, trägt der Kunde, sofern die Life Gate Consulting AG die geschäftsübliche
          Sorgfalt angewendet hat.</p>

        <h2 class="pb-sm">8. Übermittlungsfehler</h2>
        <p class="pb-sm">Den aus der Benützung von Post, Telefax, Telefon, E-Mail und anderen Übermittlungs- oder Transportarten
          entstehenden Schaden, wie z.B. aus Verlust, Verspätung, Missverständnissen, Verstümmelungen oder
          Doppelausfertigungen, trägt der Mandant, sofern die Life Gate Consulting AG die geschäftsübliche Sorgfalt
          angewendet hat.</p>

        <h2 class="pb-sm">9. Mitwirkungspflicht des Mandanten</h2>
        <p class="pb-sm">Der Mandant verpflichtet sich zur Mitwirkungspflicht. Ändert sich eine Gefahrstatsache (z.B. Standort,
          Tätigkeit, Versicherungssumme, usw.) verpflichtet sich der Mandant dies der Life Gate Consulting AG umgehend
          mitzuteilen. Dasselbe gilt für neue Gefahrstat-sachen. Stellt der Mandant Fehler bei einer Versicherungspolice
          fest, ist dies der Life Gate Consulting AG umgehend mitzuteilen.
          Ergeben sich Schäden aus der Unterlassung des Mandanten, haftet die Life Gate Consulting AG nicht.</p>

        <h2 class="pb-sm">10. Copyright</h2>
        <p class="pb-sm">Die von der Life Gate Consulting AG abgegebenen Auswertungsunterlagen und Konzepte an die Kunden unterstehen
          einem Copyright, welches die ideellen und materiellen Interessen des Urhebers (BSC/Life Gate Consulting AG) an
          seinem geistigen Eigentum schützt.</p>

        <h2 class="pb-sm">11. Sonstiges</h2>
        <p class="pb-sm">Änderungen zu den allgemeinen Geschäftsbedingungen sind nur gültig, wenn diese in schriftlicher Form
          vorliegen und von der Life Gate Consulting AG unterzeichnet sind.</p>

        <h2 class="pb-sm">12. Anwendbares Recht und Gerichtsstand</h2>
        <p class="pb-sm">Die Life Gate Consulting AG behält sich das Recht vor, die AGB jederzeit zu ändern und die gesetzlichen
          Bestimmungen anzupassen. Bei Streitbarkeit zwischen dem Mandanten und der Life Gate Consulting AG gilt
          schweizerisches Recht. Gerichtsstand ist der Sitz der Life Gate Consulting AG.</p>

        <h2 class="pb-sm">Entschädigungen Dritter</h2>
        <h2 class="pb-sm">Branche Satz in % der Nettoprämie</h2>
        <ul class="pb-sm">
          <li>Sachversicherungen 7.5 bis 15 % (Normaler Satz 15%)</li>
          <li>Haftpflichtversicherungen 7.5 bis 15% (Normaler Satz 15%)</li>
          <li>Rechtsschutzversicherungen 15% (Normaler Satz 15%)</li>
        </ul>

        <p>Motorfahrzeugversicherungen</p>
        <ul class="pb-sm">
          <li>Haftpflicht 4 bis 10% (Normaler Satz 4%)</li>
          <li>Teilkasko 7 bis 15% (Normaler Satz 15%)</li>
          <li>Kollisionskasko 7 bis 12% (Normaler Satz 12%)</li>
          <li>Unfall 7 bis 15% (Normaler Satz 15%)</li>
        </ul>

        <ul class="pb-sm">
          <li>Unfallversicherungen 3 bis 7% (Normaler Satz 5%)</li>
          <li>Unfall Zusatzversicherungen 15 bis 17.5% (Normaler Satz 15%)</li>
          <li>Krankentaggeldversicherungen 7.5 bis 10% (Normaler Satz 7.5%)</li>
          <li>Kollektivlebensversicherungen 0.5 bis 1.8% (Normaler Satz 1%)</li>
          <li>Einzellebensversicherungen 0.7 bis 4.5% der Produktionssumme*</li>
        </ul>

        <p class="pb-sm">* Die Produktionssumme setzt sich aus den einbezahlten Nettoprämien (ohne Stempelsteuer), der Laufzeit und
          des produktespezifischen Koeffizienten zusammen. Produktionsspezifische Koeffizienten sind zwischen 10 und
          100%.</p>
      </div>
    </div>
  </div>
</div>
