<div class="container">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Datenschutzerklärung</h1>
      <div class="col-lg-9">
        <p class="pb-sm">Mit dieser Datenschutzerklärung informieren wir, welche Personendaten wir wofür, wie
          und wo bearbeiten, insbesondere im Zusammenhang mit unserer www.maklermandat.ch Website und unserem sonstigen
          Angebot. Wir informieren mit dieser Datenschutzerklärung ausserdem über die Rechte von Personen, deren
          Daten wir bearbeiten.</p>

        <p class="pb-sm">Für einzelne oder zusätzliche Angebote und Leistungen können besondere, ergänzende oder
          weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie Allgemeine Geschäftsbedingungen, (AGB),
          Beratungsvereinbarungen , Nutzungsbedingungen oder Teilnahmebedingungen gelten.</p>

        <p class="pb-sm">Kontaktadressen und Datenschutzbeauftragter
          Verantwortung für die Bearbeitung von Personendaten:
          Life Gate Consulting AG
          Lindenhofweg 4 8806 Bäch SZ </p>

        <p class="pb-sm">Wir weisen darauf hin, wenn es im Einzelfall andere Verantwortliche für die Bearbeitung von
          Personendaten gibt.</p>


        <h2 class="pb-sm">2. Bearbeitung von Personendaten</h2>
        <h2 class="pb-sm">2.1 Begriffe</h2>
        <p class="pb-sm">Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen.
          Eine betroffene Person ist eine Person, über die Personendaten bearbeitet werden. Bearbeiten
          umfasst jeden Umgang mit Personendaten, unabhängig von den angewandten Mitteln
          und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Erheben, Löschen,
          Speichern, Verändern, Vernichten und Verwenden von Personendaten.Die Nutzung unserer Website ist in der Regel
          ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise
          Namen oder E-Mail-Adressen) erhoben werden, erfolgt dies – soweit möglich – stets auf freiwilliger Basis. Mit
          der Eingabe Ihrer Daten oder der Registrierung als User willigen Sie ein, dass Ihre eingegebenen Daten für die
          dienstleistungsbezogene Nutzung verwendet werden. (Dies gilt auch wenn Sie einen Kommentar oder anderen Inhalt
          erfassen/veröffentlichen.)Wir speichern die Personendaten nur in dem Umfang und so lange, wie dies für die
          Erfüllung der Zwecke, für welche die Personendaten erhoben wurden, erforderlich ist, wir an der Aufbewahrung
          ein berechtigtes Interesse haben oder dazu gesetzlich verpflichtet sind.</p>

        <p class="pb-sm">Weitergabe an Dritte Zur Vertragserfüllung, zur Wahrung unserer Interessen oder zur Erfüllung
          gesetzlicher
          Vorschriften kann es erforderlich sein, dass wir Ihre Personendaten an Dritte weitergeben.
          Wenn Sie uns von sich aus personen- oder firmenbezogene Daten zur Verfügung stellen, werden wir dabei diese
          Daten nicht über den gesetzlich zulässigen oder von Ihnen durch eine Einwilligungserklärung vorgegebenen
          Rahmen hinaus nutzen, verarbeiten oder weitergeben. Darüber hinaus geben wir Ihre Daten nur an externe
          Dienstleister weiter soweit dies zur Vertragsabwicklung erforderlich ist und diese der entsprechenden
          Vertraulichkeit und den Sorgfaltsbestimmungen zugestimmt haben. Geben wir Daten an externe Dienstleister
          weiter, so werden technische und organisatorische Massnahmen ergriffen, die gewährleisten, dass die Weitergabe
          im Einklang mit den gesetzlichen Bestimmungen des Datenschutzes erfolgt.</p>

        <p class="pb-sm">Darüber hinaus geben wir Ihre Daten nur dann weiter, wenn wir dazu gesetzlich, durch
          behördliche oder
          gerichtliche Anordnungen verpflichtet sind.
          Bitte beachten Sie die Hinweise betreffend Analyse sowie eingesetzter Plugins und Tools von
          Drittanbietern </p>
        <h2 class="pb-sm">2.2 Rechtsgrundlagen</h2>
        <p class="pb-sm">Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht wie
          insbesondere dem Bundesgesetz über den Datenschutz (DSG) und der Verordnung zum Bundesgesetz
          über den Datenschutz (VDSG). </p>
        <h2 class="pb-sm">2.3 Art, Umfang und Zweck</h2>
        <p class="pb-sm">Wir bearbeiten jene Personendaten, die erforderlich sind, um unser Angebot dauerhaft,
          nutzerfreundlich,
          sicher und zuverlässig bereitstellen zu können. Solche Personendaten können
          insbesondere in die Kategorien von Bestandes- und Kontaktdaten, Browser- und Gerätedaten,
          Inhaltsdaten, Meta- beziehungsweise Randdaten und Nutzungsdaten, Standortdaten, Verkaufs-,
          Vertrags- und Zahlungsdaten fallen.</p>
        <p class="pb-sm">Wir bearbeiten Personendaten während jener Dauer, die für den jeweiligen Zweck beziehungsweise
          die jeweiligen Zwecke oder gesetzlich erforderlich ist. Personendaten, deren Bearbeitung
          nicht mehr erforderlich ist, werden anonymisiert oder gelöscht. Personen, deren Daten
          wir bearbeiten, haben grundsätzlich ein Recht auf Löschung.</p>
        <p class="pb-sm">Wir bearbeiten Personendaten grundsätzlich nur nach Einwilligung der betroffenen Person,
          es sei denn, die Bearbeitung ist aus anderen rechtlichen Gründen zulässig, beispielsweise zur
          Erfüllung eines Vertrages mit der betroffenen Person und für entsprechende vorvertragliche
          Massnahmen, um unsere überwiegenden berechtigten Interessen zu wahren, weil die Bearbeitung
          aus den Umständen ersichtlich ist oder nach vorgängiger Information.</p>
        <p class="pb-sm">In diesem Rahmen bearbeiten wir insbesondere Angaben, die eine betroffene Person bei der
          Kontaktaufnahme – beispielsweise per Briefpost, E-Mail, Kontaktformular, Social Media oder
          Telefon – oder bei der Registrierung für ein Nutzerkonto freiwillig und selbst an uns übermittelt.
          Wir können solche Angaben beispielsweise in einem Adressbuch, in einem Customer Relationship
          Management-System (CRM-System) oder mit vergleichbaren Hilfsmitteln speichern. Sofern
          Sie Daten über andere Personen an uns übermitteln, sind Sie verpflichtet, den Datenschutz
          gegenüber solchen Personen zu gewährleisten sowie die Richtigkeit solcher Personendaten sicherzustellen.
          Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten, aus öffentlich zugänglichen
          Quellen beschaffen oder bei der Bereitstellung unseres Angebotes erheben, sofern und
          soweit eine solche Bearbeitung aus rechtlichen Gründen zulässig ist.</p>

        <h2 class="pb-sm">2.4 Bearbeitung von Personendaten durch Dritte, auch im Ausland</h2>
        <p class="pb-sm">Wir können Personendaten durch beauftragte Dritte bearbeiten lassen oder gemeinsam mit
          Dritten sowie mit Hilfe von Dritten bearbeiten oder an Dritte übermitteln. Bei solchen Dritten
          handelt es sich insbesondere um Anbieter, deren Leistungen wir in Anspruch nehmen. Wir gewährleisten
          auch bei solchen Dritten einen angemessenen Datenschutz.</p>
        <p class="pb-sm">Solche Dritte befinden sich grundsätzlich in der Schweiz sowie im Europäischen Wirtschaftsraum
          (EWR). Solche Dritte können sich aber auch in sonstigen Staaten und Territorien auf
          der Erde sowie anderswo im Universum befinden, sofern deren Datenschutzrecht nach Einschätzung
          des Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten (EDÖB) einen
          angemessenen Datenschutz gewährleistet, oder wenn aus anderen Gründen, wie beispielsweise
          durch eine entsprechende vertragliche Vereinbarung, insbesondere auf Grundlage von Standardvertragsklauseln,
          oder durch eine entsprechende Zertifizierung, ein angemessener Datenschutz
          gewährleistet ist. Ausnahmsweise kann sich ein solcher Dritter in einem Land ohne angemessenen
          Datenschutz befinden, sofern dafür die datenschutzrechtlichen Voraussetzungen
          wie beispielsweise die ausdrückliche Einwilligung der betroffenen Person erfüllt werden.</p>


        <h2 class="pb-sm">3. Rechte von betroffenen Personen</h2>
        <p class="pb-sm">Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über die Rechte gemäss
          schweizerischem Datenschutzrecht. Dazu zählen das Recht auf Auskunft sowie das Recht auf
          Berichtigung, Löschung oder Sperrung der bearbeiteten Personendaten.
          Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über ein Beschwerderecht
          bei einer zuständigen Aufsichtsbehörde. Aufsichtsbehörde für den Datenschutz in der Schweiz
          ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (EDÖB).</p>

        <h2 class="pb-sm">4. Datensicherheit</h2>
        <p class="pb-sm">Wir treffen angemessene sowie geeignete technische und organisatorische Massnahmen, um
          den Datenschutz und insbesondere die Datensicherheit zu gewährleisten. Allerdings kann die
          Bearbeitung von Personendaten im Internet trotz solcher Massnahmen immer Sicherheitslücken
          aufweisen. Wir können deshalb keine absolute Datensicherheit gewährleisten.
          Der Zugriff auf unser Online-Angebot erfolgt mittels Transportverschlüsselung (SSL / TLS, insbesondere
          mit dem Hypertext Transfer Protocol Secure, abgekürzt HTTPS). Die meisten Browser
          kennzeichnen Transportverschlüsselung mit einem Vorhängeschloss in der Adressleiste.
          Der Zugriff auf unser Online-Angebot unterliegt – wie grundsätzlich jede Internet-Nutzung –
          der anlasslosen und verdachtsunabhängigen Massenüberwachung sowie sonstiger Überwachung
          durch Sicherheitsbehörden in der Schweiz, in der Europäischen Union (EU), in den Vereinigten
          Staaten von Amerika (USA) und in anderen Staaten. Wir können keinen direkten Einfluss
          auf die entsprechende Bearbeitung von Personendaten durch Geheimdienste, Polizeistellen
          und andere Sicherheitsbehörden nehmen.</p>

        <h2 class="pb-sm">5. Nutzung der Website</h2>

        <h2 class="pb-sm">5.1 Cookies</h2>
        <p class="pb-sm">Wir können Cookies für unsere Website verwenden. Bei Cookies – bei eigenen Cookies (First-
          Party-Cookies) als auch bei Cookies von Dritten, deren Dienste wir verwenden (Cookies von
          Dritten beziehungsweise Third-Party-Cookies) – handelt es sich um Daten, die in Ihrem Browser
          gespeichert werden. Solche gespeicherten Daten müssen nicht auf traditionelle Cookies in
          Textform beschränkt sein. Cookies können keine Programme ausführen oder Schadsoftware
          wie Trojaner und Viren übertragen.</p>
        <p class="pb-sm">Cookies können beim Besuch unserer Website in Ihrem Browser temporär als «Session Cookies
          » oder für einen bestimmten Zeitraum als sogenannte permanente Cookies gespeichert
          werden. «Session Cookies» werden automatisch gelöscht, wenn Sie Ihren Browser schliessen.
          Permanente Cookies haben eine bestimmte Speicherdauer. Sie ermöglichen insbesondere, Ihren
          Browser beim nächsten Besuch unsererWebsite wiederzuerkennen und dadurch beispielsweise
          die Reichweite unserer Website zu messen. Permanente Cookies können aber beispielsweise
          auch für Online-Marketing verwendet werden.</p>

        <p class="pb-sm">Sie können Cookies in Ihren Browser-Einstellungen jederzeit ganz oder teilweise deaktivieren
          sowie löschen. Ohne Cookies steht unsere Website allenfalls nicht mehr in vollem Umfang zur
          Verfügung. Wir ersuchen Sie – sofern und soweit erforderlich – aktiv um Ihre ausdrückliche
          Einwilligung für die Verwendung von Cookies.</p>
        <p class="pb-sm">Bei Cookies, die für die Erfolgs- und Reichweitenmessung oder für Werbung verwendet werden,
          ist für zahlreiche Dienste ein allgemeiner Widerspruch («Opt-out») über die Network
          Advertising Initiative (NAI), YourAdChoices (Digital Advertising Alliance) oder Your Online
          Choices (European Interactive Digital Advertising Alliance, EDAA) möglich.</p>

        <h2 class="pb-sm">5.2 Server-Logdateien</h2>
        <p class="pb-sm">Wir können für jeden Zugriff auf unsere Website nachfolgende Angaben erfassen, sofern diese
          von Ihrem Browser an unsere Server-Infrastruktur übermittelt werden oder von unserem Webserver
          ermittelt werden können: Datum und Zeit einschliesslich Zeitzone, Internet Protocol
          (IP)-Adresse, Zugriffsstatus (HTTP-Statuscode), Betriebssystem einschliesslich Benutzeroberfläche
          und Version, Browser einschliesslich Sprache und Version, aufgerufene einzelne Unter-
          Seite unserer Website einschliesslich übertragener Datenmenge, zuletzt im gleichen Browser-
          Fenster aufgerufene Webseite (Referer beziehungsweise Referrer).
          Wir speichern solche Angaben, die auch Personendaten darstellen können, in Server-Logdateien.
          Die Angaben sind erforderlich, um unser Online-Angebot dauerhaft, nutzerfreundlich und
          zuverlässig bereitstellen sowie um die Datensicherheit und damit insbesondere den Schutz von
          Personendaten sicherstellen zu können – auch durch Dritte oder mit Hilfe von Dritten.</p>


        <h2 class="pb-sm">5.3 Zählpixel</h2>
        <p class="pb-sm">Wir können Zählpixel auf unsererWebsite verwenden. Zählpixel werden auch alsWeb-Beacons
          bezeichnet. Bei Zählpixeln – auch von Dritten, deren Dienste wir verwenden – handelt es sich
          um kleine, üblicherweise nicht sichtbare Bilder, die beim Besuch unserer Website automatisch
          abgerufen werden. Mit Zählpixeln können die gleichen Angaben wie in Server-Logdateien erfasst
          werden.</p>

        <h2 class="pb-sm">6. Benachrichtigungen und Mitteilungen</h2>
        <p class="pb-sm">Wir versenden Benachrichtigungen und Mitteilungen wie beispielsweise Newsletter per E-Mail
          und über andere Kommunikationskanäle wie beispielsweise Instant Messaging.</p>

        <h2 class="pb-sm">6.1 Erfolgs- und Reichweitenmessung</h2>
        <p class="pb-sm">Benachrichtigungen und Mitteilungen können Weblinks oder Zählpixel enthalten, die erfassen,
          ob eine einzelne Mitteilung geöffnet wurde und welcheWeblinks dabei angeklickt wurden. Solche
          Weblinks und Zählpixel können die Nutzung von Benachrichtigungen und Mitteilungen
          auch personenbezogen erfassen. Wir benötigen diese statistische Erfassung der Nutzung für
          die Erfolgs- und Reichweitenmessung, um Benachrichtigungen und Mitteilungen aufgrund der
          Bedürfnisse und Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und nutzerfreundlich
          sowie dauerhaft, sicher und zuverlässig anbieten zu können.</p>

        <h2 class="pb-sm">6.2 Einwilligung und Widerspruch</h2>
        <p class="pb-sm">Sie müssen grundsätzlich in die Verwendung Ihrer E-Mail-Adresse und Ihrer sonstigen
          Kontaktadressen
          ausdrücklich einwilligen, es sei denn, die Verwendung ist aus anderen rechtlichen
          Gründen zulässig. Für eine allfällige Einwilligung für den Erhalt von E-Mails verwenden wir
          nach Möglichkeit das «Double Opt-in»-Verfahren, das heisst Sie erhalten eine E-Mail mit einem
          Weblink, den Sie zur Bestätigung anklicken müssen, damit kein Missbrauch durch unberechtigte
          Dritte erfolgen kann. Wir können solche Einwilligungen einschliesslich Internet Protocol
          (IP)-Adresse sowie Datum und Zeit aus Beweis- und Sicherheitsgründen protokollieren.
          Sie können sich grundsätzlich jederzeit von Benachrichtigungen und Mitteilungen wie beispielsweise
          Newslettern abmelden. Mit der Abmeldung können Sie insbesondere der statistischen
          Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung widersprechen. Vorbehalten
          bleiben Benachrichtigungen und Mitteilungen, die für unser Angebot zwingend erforderlich
          sind.</p>


        <h2 class="pb-sm">7. Social Media</h2>
        <p class="pb-sm">Wir sind auf Social Media-Plattformen und anderen Online-Plattformen präsent, um mit
          interessierten
          Personen kommunizieren und über unser Angebot informieren zu können. Dabei
          können Personendaten auch ausserhalb der Schweiz und des Europäischen Wirtschaftsraumes
          (EWR) bearbeitet werden.</p>
        <p class="pb-sm">Es gelten jeweils auch die Allgemeinen Geschäftsbedingungen (AGB) und Nutzungsbedingungen
          sowie Datenschutzerklärungen und sonstigen Bestimmungen der einzelnen Betreiber solcher
          Online-Plattformen. Diese Bestimmungen informieren insbesondere über die Rechte von
          betroffenen Personen, wozu insbesondere das Recht auf Auskunft zählt.</p>

        <h2 class="pb-sm">8. Dienste von Dritten</h2>
        <p class="pb-sm">Wir verwenden Dienste von Dritten, um unser Angebot dauerhaft, nutzerfreundlich, sicher und
          zuverlässig bereitstellen zu können. Solche Dienste können auch dazu dienen, Inhalte in unsere
          Website einbetten zu können. Solche Dienste – beispielsweise Hosting- und Speicherdienste,
          Video-Dienste und Zahlungsdienste – benötigen Ihre Internet Protocol (IP)-Adresse, da solche
          Dienste die entsprechenden Inhalte ansonsten nicht übermitteln können.
          Für ihre eigenen sicherheitsrelevanten, statistischen und technischen Zwecke können Dritte,
          deren Dienste wir verwenden, auch Daten im Zusammenhang mit unserem Angebot sowie aus
          anderen Quellen – unter anderem mit Cookies, Logdateien und Zählpixeln – aggregiert, anonymisiert
          oder pseudonymisiert bearbeiten.</p>


        <h2 class="pb-sm">Wir verwenden insbesondere:</h2>
        <ul class="pb-sm">
          <li>Dienste von Google: Anbieterinnen: Google LLC (USA) / Google Ireland Limited (Irland)
            für Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum (EWR) und in der Schweiz;
            Allgemeine Angaben zum Datenschutz: «Grundsätze zu Datenschutz und Sicherheit», Datenschutzerklärung,
            «Google ist der Einhaltung der anwendbaren Datenschutzgesetze verpflichtet
            », «Leitfaden zum Datenschutz in Google-Produkten», «Wie wir Daten vonWebsites
            oder Apps verwenden, auf beziehungsweise in denen unsere Dienste genutzt werden» (Angaben
            von Google), «So verwendet Google Cookies», «Personalisierte Werbung» (Aktivierung
            / Deaktivierung / Einstellungen).
          </li>
        </ul>

        <h2 class="pb-sm">8.1 Digitale Infrastruktur</h2>
        <p class="pb-sm">Wir verwenden Dienste von Dritten, um benötigte digitale Infrastruktur für unser Angebot in
          Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und Speicherdienste von
          spezialisierten Anbietern.</p>
        <p class="pb-sm">Wir verwenden insbesondere:</p>
        <ul class="pb-sm">
          <li class="pb-sm">Hostpoint: Hosting; Anbieterin: Hostpoint AG (Schweiz); Angaben zum Datenschutz:
            Datenschutzerklärung.
          </li>
          <li>WordPress.com: Blog-Hosting und Website-Baukasten; Anbieterinnen: Automattic Inc.
            (USA) / Aut O’Mattic A8C Ireland Ltd. (Irland) für Nutzerinnen und Nutzer unter anderem in
            Europa; Angaben zum Datenschutz: Datenschutzerklärung, Cookie-Richtlinie.
          </li>
        </ul>


        <h2 class="pb-sm">8.2 Kartenmaterial</h2>
        <p class="pb-sm">Wir verwenden Dienste von Dritten, um Karten in unsere Website einbetten zu können.</p>
        <p class="pb-sm">Wir verwenden insbesondere:</p>
        <ul class="pb-sm">
          <li>Google Maps einschliesslich Google Maps Platform: Kartendienst; Google Maps-spezifische
            Angaben zum Datenschutz: «Wie Google Standortinformationen verwendet».
          </li>
        </ul>

        <h2 class="pb-sm">8.3 Schriftarten</h2>
        <p class="pb-sm">Wir verwenden Dienste von Dritten, um ausgewählte Schriftarten sowie Icons, Logos und Symbole
          in unsere Website einbetten zu können.</p>
        <p class="pb-sm">Wir verwenden insbesondere:</p>
        <ul class="pb-sm">
          <li>Google Fonts: Schriftarten; Google Fonts-spezifische Angaben zum Datenschutz: «Was bedeutet
            die Verwendung der Google Fonts-API für die Privatsphäre meiner Nutzerinnen und
            Nutzer?» («What does using the Google Fonts API mean for the privacy of my users?»).
          </li>
        </ul>

        <h2 class="pb-sm">8.4 Werbung</h2>
        <p class="pb-sm">Wir nutzen die Möglichkeit, gezielt Werbung für unser Angebot bei Dritten wie beispielsweise
          Social Media-Plattformen und Suchmaschinen anzeigen zu lassen.
          Wir möchten mit solcher Werbung insbesondere Personen erreichen, die sich für unser Angebot
          interessieren oder unser Angebot bereits nutzen (Remarketing und Targeting). Dafür
          können wir entsprechende – allenfalls auch personenbezogene – Angaben an Dritte, die solche
          Werbung ermöglichen, übermitteln. Wir können ausserdem feststellen, ob unsere Werbung erfolgreich
          ist, das heisst insbesondere, ob sie zu Besuchen auf unserer Website führt (Conversion
          Tracking).</p>
        <p class="pb-sm">Dritte, bei denen wir werben und wo Sie als Nutzerin oder Nutzer angemeldet sind, können die
          Nutzung unseres Angebotes allenfalls Ihrem dortigen Profil zuordnen.</p>
        <p class="pb-sm">Wir verwenden insbesondere:</p>
        <ul class="pb-sm">
          <li class="pb-sm">Facebook Ads: Social Media-Werbung; Anbieterinnen: Facebook Inc. (USA) / Facebook Ireland
            Ltd. (Irland); Angaben zum Datenschutz: Remarketing und Targeting insbesondere mit
            dem Facebook-Pixel sowie Custom Audiences einschliesslich Lookalike Audiences,
            Datenschutzerklärung, «Werbepräferenzen» (Anmeldung als Nutzerin oder Nutzer erforderlich).
          </li>
          <li class="pb-sm">Google Ads: Suchmaschinen-Werbung; Google Ads-spezifische Angaben zum Datenschutz:
            Werbung unter anderem aufgrund von Suchanfragen, wobei verschiedene Domainnamen –
            insbesondere doubleclick.net, googleadservices.com und googlesyndication.com – für Google
            Ads verwendet werden, «Werbung» (Google), «Warum sehe ich eine bestimmte Werbung?
            ».
          </li>
          <li>Instagram Ads: Social Media-Werbung; Anbieterinnen: Facebook Inc. (USA) / Facebook Ireland
            Ltd. (Irland); Angaben zum Datenschutz: Remarketing und Targeting insbesondere mit
            Facebook-Pixel sowie Custom Audiences einschliesslich Lookalike Audiences, Datenschutzerklärung
            (Instagram), Datenschutzerklärung (Facebook), «Werbepräferenzen» (Instagram)
            (Anmeldung als Nutzerin oder Nutzer erforderlich), «Werbepräferenzen» (Facebook)
            (Anmeldung als Nutzerin oder Nutzer erforderlich).
          </li>
        </ul>

        <h2 class="pb-sm">9. Erfolgs- und Reichweitenmessung</h2>
        <p class="pb-sm">Wir verwenden Dienste und Programme, um zu ermitteln, wie unser Online-Angebot genutzt
          wird. In diesem Rahmen können wir zum Beispiel den Erfolg und die Reichweite unseres Online-
          Angebotes sowie die Wirkung von Verlinkungen Dritter auf unsere Website messen. Wir
          können aber beispielsweise auch ausprobieren und vergleichen, wie unterschiedliche Versionen
          unseres Online-Angebotes oder Teile unseres Online-Angebotes genutzt werden («A/B-Test»-
          Methode). Aufgrund der Ergebnisse der Erfolgs- und Reichweitenmessung können wir insbesondere
          Fehler beheben, besonders gefragte Inhalte stärken oder Verbesserungen an unserem
          Online-Angebot vornehmen.</p>
        <p class="pb-sm">Bei der Verwendung von Diensten und Programmen für die Erfolgs- und Reichweitenmessung
          müssen die Internet Protocol (IP)-Adressen von einzelnen Nutzerinnen und Nutzern gespeichert
          werden. IP-Adressen werden grundsätzlich gekürzt, um durch die entsprechende Pseudonymisierung
          dem Grundsatz der Datensparsamkeit zu folgen sowie den Datenschutz der Besucherinnen
          und Besucher unserer Website zu verbessern («IP-Masking»).</p>
        <p class="pb-sm">Bei der Verwendung von Diensten und Programmen für die Erfolgs- und Reichweitenmessung
          können Cookies zum Einsatz kommen und Nutzerprofile erstellt werden. Nutzerprofile umfassen
          beispielsweise die besuchten Seiten oder betrachteten Inhalte auf unserer Website, Angaben
          zur Grösse von Bildschirm oder Browser-Fenster und den – zumindest ungefähren – Standort.
          Grundsätzlich werden Nutzerprofile ausschliesslich pseudonymisiert erstellt. Wir verwenden
          Nutzerprofile nicht für die Identifizierung einzelner Besucherinnen und Besucher unserer</p>

        <p class="pb-sm">Website. Einzelne Dienste, bei denen Sie als Nutzerin oder Nutzer angemeldet sind, können die
          Nutzung unseres Online-Angebotes allenfalls Ihrem Profil beim jeweiligen Dienst zuordnen,
          wobei Sie dafür üblicherweise vorgängig Ihre Einwilligung in diese Zuordnung erteilen mussten.</p>

        <p class="pb-sm">Wir verwenden insbesondere:</p>
        <ul class="pb-sm">
          <li>Google Analytics: Erfolgs- und Reichweitenmessung; Google Analytics-spezifische Angaben
            zum Datenschutz: Messung auch über verschiedene Browser und Geräte hinweg
            (Cross-Device Tracking) sowie mit pseudonymisierten Internet Protocol (IP)-Adressen, die
            nur ausnahmsweise vollständig an Google in den USA übertragen werden, «Datenschutz»,
            «Browser Add-on zur Deaktivierung von Google Analytics».
          </li>
        </ul>

        <h2 class="pb-sm">10. Schlussbestimmungen</h2>
        <p class="pb-sm">Wir haben diese Datenschutzerklärung mit dem Datenschutz-Generator von Datenschutzpartner
          erstellt.</p>
        <p class="pb-sm">Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen. Wir werden über
          solche Anpassungen und Ergänzungen in geeigneter Form informieren, insbesondere durch
          Veröffentlichung der jeweils aktuellen Datenschutzerklärung auf unserer Website.</p>
      </div>
    </div>
  </div>
</div>
