<div class="container">
  <div class="row pt-xl">
    <div class="col-lg-6 offset-lg-3">
      <h1 class="pb-sm">Login</h1>
      <h2 class="pb-sm">
        Melde Dich mit Deiner E-Mail Adresse und Deinem Passwort an
      </h2>
        <form (ngSubmit)="login()">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>E-Mail</mat-label>
            <input matInput type="email" [(ngModel)]="email" name="email" placeholder="Email">
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Passwort</mat-label>
            <input matInput type="password" [(ngModel)]="password" name="password" placeholder="Password">
          </mat-form-field>
          <button mat-button mat-flat-button color="primary" type="submit">
            Login
          </button>
        </form>
    </div>
  </div>
</div>

