<ng-container *ngIf="successType === 'mandatesubmission'">
  <div class="container">
    <div class="row pt-xl">
      <div class="col">
        <h1 class="pb-sm">Dein Maklermandat wurde erfolgreich übermittelt</h1>
        <h2 class="col-lg-9 pb-sm">
          Du erhälst in Kürze eine Mailbestätigung mit dem unterzeichneten Maklermandat.
          Wir bedanken uns für Dein Vertrauen und stehen für Fragen und Auskünfte stets zur Verfügung.
        </h2>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="successType === 'mandatelink'">
  <div class="container">
    <div class="row pt-xl">
      <div class="col">
        <h1 class="pb-sm">Das Maklermandat wurde erfolgreich zur Unterzeichnung übermittelt</h1>
        <h2 class="col-lg-9 pb-sm">
          Es wird in Kürze eine E-Mail mit dem entsprechenden Link.
          Alternativ kannst Du jederzeit nochmals einen Link von Deinem Account aus versenden.
        </h2>
      </div>
    </div>
  </div>
</ng-container>
