import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public API_URL = environment.apiUrl;

  constructor(private  _httpClient: HttpClient) {}

  getList(id:any) {
    return this._httpClient.get(`${this.API_URL}api/v1/brokermandate/${id}`);
  }

  getAccountManagerList(slug:any) {
    return this._httpClient.get(`${this.API_URL}api/v1/account/${slug}`);
  }

  createClient(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/client/`, data);
  }

  submitClient(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/client`, data);
  }

  submitManager(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/manager`, data);
  }

  submitPdf(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/pdf`, data);
  }

  submitPolice(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/police`, data);
  }

  submitMandat(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/`, data);
  }

  saveForLater(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/mandatesubmission/save-for-later`, data);
  }

  login(data:any, headers:any){
    //return this._httpClient.post(`${this.API_URL}api/auth/login/`, data, headers);
    return this._httpClient.post(`${this.API_URL}api/v1/account/login`, data, headers);
  }

  forgottPassword(data:any){
    return this._httpClient.post(`${this.API_URL}api/v1/forgot-password`, data);
  }

  logout(headers:any){
    return this._httpClient.post(`${this.API_URL}api/auth/logout/`, '', headers);
  }

  getAccount(headers:any){
    return this._httpClient.get(`${this.API_URL}api/v1/account/`, headers);
  }

  getMandateList(id:any, headers:any, page: number, pageSize: number){
    return this._httpClient.get(`${this.API_URL}api/v1/mandatesubmission/${id}?page=${page}&page_size=${pageSize}`, headers);
  }

  getMandateDetail(id:any){
    return this._httpClient.get(`${this.API_URL}api/v1/mandatesubmission/detail/${id}`);
  }

  updateMandateDetail(id:any, data:any){
    return this._httpClient.put(`${this.API_URL}api/v1/mandatesubmission/detail/${id}`, data);
  }

  getBrokerList(id:any, headers:any, page: number, pageSize: number){
    return this._httpClient.get(`${this.API_URL}api/v1/mandatesubmission/broker/${id}?page=${page}&page_size=${pageSize}`, headers);
  }


}
