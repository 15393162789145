import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  colors: any = {
    "primary": "#65352A",
    "secondary": "#E2CBC0",
    "surface": "#F0EFEC",
  };

  colorObservable = new BehaviorSubject(this.colors);
  logoObservable = new BehaviorSubject('../../../assets/images/logo/life-gate-logo.svg');
  urlObservable = new BehaviorSubject('');
  addressObservable = new BehaviorSubject('');


  constructor(private _apiService: ApiService) {
  }


  updateTheme(slug: any) {
    this._apiService.getAccountManagerList(slug).subscribe({
      next: (data: any) => {
        //let logoUrl = this._apiService.API_URL + data.theme.logo.substring(1)
        let logoUrl = data.theme.logo
        this.colors = {
          "primary":  data.theme.primary_color,
          "secondary": data.theme.secondary_color,
          "surface": data.theme.surface_color,
        }
        this.colorObservable.next(this.colors);
        this.logoObservable.next(logoUrl);
        this.urlObservable.next(data.slug);
        this.addressObservable.next(data.address);
      },
      error: (error: any) => {
        console.error('There was an error!', error);
      }
    });
  }

}

