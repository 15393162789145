import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from "../../shared/services/authorization.service";
import {ApiService} from "../../shared/services/api.service";
import {Router, RouterLink} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ThemeService} from "../../shared/services/theme.service";

@Component({
  selector: 'app-homepage-subbroker',
  templateUrl: './homepage-subbroker.component.html',
  styleUrls: ['./homepage-subbroker.component.scss'],
  standalone: true,
  imports: [
    RouterLink
  ]
})
export class HomepageSubbrokerComponent implements OnInit {

  address: any;

  constructor(private _authService: AuthorizationService,
              private _apiService: ApiService,
              private _router: Router,
              private _snackBar: MatSnackBar,
              private _themeService: ThemeService) {
  }

  ngOnInit() {
    this.getAddress();
  }

  getAddress() {
    this._themeService.addressObservable.subscribe((response: any) => {
      this.address = response;
      console.log(this.address)
    })
  }

}
