import { Directive, ElementRef, Input, Renderer2, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appViewportObserver]'
})
export class ViewportObserverDirective implements OnInit, OnDestroy {
  @Input() animationClass: string = 'fade-in'; // CSS-Klasse für die Animation
  private observer!: IntersectionObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initObserver();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private initObserver(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.renderer.addClass(this.el.nativeElement, this.animationClass); // Klasse hinzufügen
          } else {
            this.renderer.removeClass(this.el.nativeElement, this.animationClass); // Klasse entfernen
          }
        });
      },
      {
        threshold: 0.1, // 30% des Elements müssen sichtbar sein
      }
    );

    this.observer.observe(this.el.nativeElement);
  }
}
