<div class="container">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Disclaimer / Rechtliche Hinweise</h1>
      <div class="col-lg-9">
        <p>Willkommen auf der Website der Unternehmen der Life Gate Consulting AG. Bitte lesen Sie die nachfolgenden
          Bedingungen – sie sind auf Veranlassung unserer Rechtsberater entstanden – sorgfältig durch. Mit dem Zugriff
          auf diese Website und ihre Seiten (nachfolgend „Life Gate Consulting AG Website“ genannt) erklären Sie, dass
          Sie die folgenden Benutzungsbedingungen und rechtlichen Iationen im Zusammenhang mit der Dr. Blumer-Website
          verstanden haben und anerkennen. Wenn Sie mit den nachfolgenden Bedingungen nicht einverstanden sind,
          unterlassen Sie bitte den Zugriff auf die Life Gate Consulting AG Website.</p>
        <h2 class="pt-sm">Benutzungsbedingungen und rechtliche Informationen</h2>
        <p>Lokale gesetzliche Einschränkungen Ausländische
          Rechtsordnungen können die Publikation der Life Gate Consulting AG -Website oder die Möglichkeit des Zugriffs
          auf die Life Gate Consulting AG - Website verbieten. Personen, welche solchen Einschränkungen unterliegen,
          dürfen die Life Gate Consulting AG -Website daher nicht besuchen. Einschränkungen gelten insbesondere für
          Bürger der Vereinigten Staaten von Amerika (U.S.A.) und Grossbritanniens (UK) und für Personen, die in den
          U.S.A. oder UK ihren Wohnsitz haben.</p>
        <h2 class="pt-sm">Rechte an der Life Gate Consulting AG Website</h2>
        <p>Der gesamte Inhalt der Life Gate Consulting AG-Website ist
          urheberrechtlich geschützt (alle Rechte vorbehalten). Das Herunterladen oder Ausdrucken einzelner Seiten
          und/oder Teilbereiche der Life Gate Consulting AG-Website ist gestattet, sofern weder die Copyrightvermerke
          noch andere gesetzlich geschützten Bezeichnungen entfernt werden. Wenn Sie Software oder Daten von der Life
          Gate -Website herunterladen oder auf andere Weise vervielfältigen, bleiben sämtliche Eigentumsrechte bei der
          Life Gate Consulting AG. Kein Bestandteil der Life Gate Consulting AG-Website ist so konzipiert, dass dadurch
          eine Lizenz oder ein Recht zur Nutzung eines Bildes, einer eingetragenen Marke oder eines Logos eingeräumt
          wird. Durch ein Herunterladen oder ein Kopieren der Life Gate Consulting AG -Website werden Ihnen keinerlei
          Rechte im Hinblick auf die Software oder Elemente auf der Life Gate Consulting AG - Website übertragen. Die
          Life Gate Consulting AG behält sich sämtliche Rechte (insbesondere Urheber- und Markenrechte) hinsichtlich
          aller Elemente auf der Life Gate Consulting AG - Website vor. Das (vollständige oder teilweise) Reproduzieren,
          Übermitteln (elektronisch oder mit anderen Mitteln), Modifizieren, Verknüpfen oder Benutzen der Life Gate
          Consulting AG -Website für öffentliche oder kommerzielle Zwecke ist ohne vorherige schriftliche Zustimmung
          der Life Gate Consulting AG untersagt.</p>
        <h2 class="pt-sm">Keine Zusicherungen und Garantien</h2>
        <p>Sämtliche in der Life Gate Consulting AG -Website enthaltenen Aussagen und
          Informationen wurden von der Life Gate Consulting AG oder Dritten zum Zeitpunkt der Veröffentlichung
          sorgfältig recherchiert und geprüft. Für Richtigkeit, Vollständigkeit und Aktualität können jedoch weder die
          Life Gate Consulting AG noch Dritte eine explizite bzw. implizite Zusicherung oder Garantie (einschliesslich
          Haftung) abgeben. Die Life Gate Consulting AG kann auch keine Zusicherung dafür abgeben, dass die Aussagen
          und Informationen nicht durch technische Störungen (Übermittlungsfehler, technische Mängel, Unterbrüche,
          Eingriffe unbefugter Dritter, etc.) verfälscht werden können. Die in der Life Gate Consulting AG-Website
          enthaltenen Aussagen und Informationen werden von der Life Gate Consulting AG Gruppe ausschliesslich für den
          privaten Gebrauch sowie für Informationszwecke bereitgestellt; sie können jederzeit und ohne vorherige
          Ankündigung geändert werden.</p>
        <h2 class="pt-sm">Kein Angebot</h2>
        <p>Die in der Life Gate Consulting AG-Website enthaltenen Aussagen und Informationen sind nicht als
          Angebot oder Empfehlung bestimmter Vorsorge, Versicherungs- oder Anlageprodukte zu verstehen. Hier enthaltene
          Aussagen und Informationen können eine auf die individuellen Verhältnisse des Kunden / Anlegers abgestellte,
          anleger- und objektorientierte Beratung nicht ersetzen. Bitte setzen Sie sich gegebenenfalls mit einem
          Kundenberater der Life Gate Consulting AG in Verbindung.</p>
        <h2 class="pt-sm">Performance Haftungsbeschränkung</h2>
        <p>Jegliche Haftung (einschliesslich für Fahrlässigkeit) der Life Gate
          Consulting AG für Kosten oder Schäden irgendwelcher Art (einschliesslich direkte und indirekte Schäden,
          immaterielle Schäden sowie Folgeschäden), die sich aus oder im Zusammenhang mit dem Zugriff auf die Life Gate
          Consulting AG - Website oder deren Benutzung (bzw. aus der Unmöglichkeit des Zugriffs oder der Benutzung)
          ergeben oder die aus oder im Zusammenhang mit der Verknüpfung (linking) mit anderen Websites entstehen, ist
          ausgeschlossen. Dies gilt auch wenn die Life Gate Consulting AG auf die Möglichkeit solcher Schäden
          hingewiesen wurde.</p>
        <h2 class="pt-sm">Verknüpfte Websites</h2>
        <p>Die Life Gate Consulting AG kann Verknüpfungen (Links) zu Websites von Dritten
          enthalten, auf deren Inhalt die Life Gate Consulting AG keinen Einfluss und die sie nicht geprüft hat. Die
          Life Gate Consulting AG übernimmt für deren Benutzung und für deren Inhalte, den darauf angebotenen
          Produkten, Dienstleistungen oder sonstigen Angeboten keine Verantwortung. Gleiches gilt für Websites, die
          Links auf die Life Gate Consulting AG anbieten. Zudem ist für einen Link, der von einer fremden Website auf
          die Life Gate Consulting AG-Website führt, stets vorgängig eine ausdrückliche Bewilligung von der Life Gate
          Consulting AG einzuholen.</p>
        <h2 class="pt-sm">Google Analytics</h2>
        <p>Die Life Gate Consulting AG-Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
          („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Life Gate Consulting AG-Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschliesslich Ihrer IP-Adresse) wird an
          einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen,
          um Ihre Nutzung der Life Gate Consulting AG- Website auszuwerten, um Reports über die Websiteaktivitäten für
          die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
          verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte
          übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
          verarbeiten. Google wird Ihre IP-Adresse nicht mit anderen Daten der Google in Verbindung bringen. Sie können
          die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir
          weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen der Life Gate
          Consulting AG -Website voll umfänglich nutzen können. Durch die Nutzung der Life Gate Consulting AG-Website
          erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen
          Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
        <h2 class="pt-sm">E-Mail</h2>
        <p>Die Benutzung von E-mail und elektronischen Formularen birgt Gefahren in sich. Die Daten können unter
          Umständen von Dritten eingesehen und verändert werden. Sie sollten uns deshalb insbesondere auch keine
          vertraulichen Daten auf diesem Weg übermitteln. Rechtswirksamkeit Diese Benutzungsbedingungen und rechtlichen
          Informationen sind als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seiten verwiesen wurde.
          Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht
          vollständig entsprechen sollte, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer
          Gültigkeit davon unberührt.</p>


      </div>
    </div>
  </div>
</div>
