import {Component} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ApiService} from "../../shared/services/api.service";
import {HttpClient} from "@angular/common/http";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-forgott-password',
  templateUrl: './forgott-password.component.html',
  styleUrls: ['./forgott-password.component.scss'],
  standalone: true,
  imports: [
    FormsModule
  ]
})
export class ForgottPasswordComponent {

  email = '';

  constructor(private http: HttpClient,
              private _apiService: ApiService) {
  }

  onSubmit() {
    let data = {
      "email": this.email
    }

    this._apiService.forgottPassword(data)
      .subscribe({
        next: (response: any) => {
          console.log(response)
        },
        error: (error: any) => {
          console.error('There was an error!', error);
        }
      });
  }

}
