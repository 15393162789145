import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PdfService } from "./pdf.service";
import { environment } from "../../../environments/environment";
import { LoadingService } from "./loading.service";
import { Router } from "@angular/router";
import {catchError, Observable, tap, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public EMAIL_URL = environment.emailUrl;
  public LIVE_URL = environment.liveUrl;
  public BROKER_MAIL = environment.brokerMail;

  constructor(private http: HttpClient,
              private _pdfService: PdfService,
              private _loadingService: LoadingService,
              private _router: Router) {
  }

  clientEmailConfirmation(data: any) {
    console.log(data);
    let client = data.mandate_client;
    let manager = data.mandate_manager;

    let message = '<p>Guten Tag ' + client.first_name + ' ' + client.last_name + '</p>' +
      '<p>Das Maklermandat wurde erfolgreich übermittelt. Vielen Dank für Dein Vertrauen, im Anhang findest Du die unterzeichnete Version davon für Deine Unterlagen.</p>' +
      '<p>Wir freuen uns Dich in Zukunft bezüglich Deinen Versicherungsfragen beraten zu dürfen und stehen diesbezüglich stets an Deiner Seite.</p>' +
      '<p>Bitte wende Dich bei Fragen oder Auskünften an uns oder direkt an ' + manager.first_name + ' ' + manager.last_name + '</p>' +
      '<p>Freundliche Grüsse<br>' +
      'Dein ' + manager.company + ' Team' + '</p>' +
      '<p>' + manager.company + '<br>' + manager.street + '<br>' + manager.post_code + ' ' +
      manager.city + '<br><br>' + manager.email + '<br>' + manager.phone + '</p>';

    const formData = new FormData();
    formData.append('subject', 'E-Mail Bestätigung Maklermandat');
    formData.append('message', message);
    formData.append('sender', 'noreply@maklermandat.ch');
    formData.append('receiver', client.email);
    formData.append('pdf', data.pdf_files[0].pdf_file);
    if (data.pdf_files[1]) {
      formData.append('pdf', data.pdf_files[1].pdf_file);
    }


    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    this.http.post('https://lifegate-broker-app-stage.us.aldryn.io/sendmail', formData, {headers: headers}).subscribe({
      next: (data: any) => {
        this._loadingService.isLoading.next(false);
        console.log('E-Mail an Kunde gesendet')
        this._router.navigate(['success','mandatesubmission']);
      },
      error: (error: any) => {
        this._loadingService.isLoading.next(false);
        console.error('There was an error!', error);
      }
    });

  }


  managerEmailConfirmation(data: any) {
    let client = data.mandate_client;
    let manager = data.mandate_manager;

    let message = '<p>Ein neues Maklermandat wurde übermittelt.</p>' +
      '<p><strong>Subbroker:</strong><br>' +
      manager.company + '<br>' +
      manager.street + '<br>' +
      manager.post_code + ' ' + manager.city + '</p>' +
      '<p><strong>Kundenberater:</strong><br>' +
      manager.first_name + ' ' + manager.last_name + '<br>' +
      manager.email + '<br>' +
      manager.phone + '</p>';
    '<p><strong>Kundendaten:</strong><br>' +
    client.first_name + ' ' + client.last_name + '<br>' +
    client.street + '<br>' +
    client.post_code + ' ' + client.city + '<br><br>' +
    client.email + '<br>' +
    client.phone + '</p>';

    if (data.police.length != 0) {
      message += '<p><strong>Versicherungsverträge:</strong><br>';
      for (let police of data.police) {
        message += 'Firma: ' + police.company + '<br>Vertrag: ' + police.contract + '<br>Police: ' + police.police + '<br><br>';
      }
      message += '</p>'
    }

    let subject = 'Neues Maklermandat ' + client.first_name + ' ' + client.last_name
    let receiverEmails = [manager.email, this.BROKER_MAIL].join(',');


    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    formData.append('sender', 'noreply@maklermandat.ch');
    formData.append('receiver', receiverEmails);
    formData.append('pdf', data.pdf_files[0].pdf_file);
    if (data.pdf_files[1]) {
      formData.append('pdf', data.pdf_files[1].pdf_file);
    }

    const headers = new HttpHeaders();
    //headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'multipart/form-data');

    this.http.post(this.EMAIL_URL, formData, {headers: headers}).subscribe({
      next: (data: any) => {
        this._loadingService.isLoading.next(false);
        console.log('E-Mail an Kundenberater gesendet')
        this._router.navigate(['success', 'mandatesubmission']);
      },
      error: (error: any) => {
        this._loadingService.isLoading.next(false);
        console.error('There was an error!', error);
      }
    });
  }

  clientSignatureLink(data: any, submission: any, slug: any, successmessage: any) {
    this._loadingService.isLoading.next(true);
    let client = data.mandate_client;
    let manager = data.mandate_manager;

    let message = '<p>Guten Tag ' + client.first_name + ' ' + client.last_name + '</p>' +
      '<p>Unter folgendem Link steht das Maklermandat zur Unterzeichnung bereit:</p>' +
      '<a href="' + this.LIVE_URL + slug + '/maklermandat/' + submission.id + '">' + this.LIVE_URL + '/' + slug + '/maklermandat/' + submission.id + '</a>' +
      '<p>Bitte wende Dich bei Fragen oder Auskünften an uns oder direkt an ' + manager.first_name + ' ' + manager.last_name + '</p>' +
      '<p>Freundliche Grüsse<br>' +
      'Dein ' + manager.company + ' Team</p>' +
      '<p>' + manager.company + '<br>' +
      manager.street + '<br>' +
      manager.post_code + ' ' + manager.city + '<br><br>' +
      manager.email + '<br>' +
      manager.phone + '</p>';

    const formData = new FormData();
    formData.append('subject', 'E-Mail Bestätigung Maklermandat');
    formData.append('message', message);
    formData.append('sender', 'noreply@maklermandat.ch');
    formData.append('receiver', client.email);


    const headers = new HttpHeaders();
    headers.append('Content-Type', 'text/html');

    this.http.post(this.EMAIL_URL, formData, {headers: headers}).subscribe({
      next: (data: any) => {
        this._loadingService.isLoading.next(false);
        if(successmessage){
          this._router.navigate(['success', 'mandatelink'])
        };
        console.log('E-Mail an Kunde gesendet')
      },
      error: (error: any) => {
        this._loadingService.isLoading.next(false);
        console.error('There was an error!', error);
      }
    });
  }

  sendContactEmail(contactData: any): Observable<any> {
    const adminEmailMessage = this.createEmailMessage({
      subject: 'Neue Kontaktanfrage',
      message: `
      <p>Eine neue Kontaktanfrage von Maklermandat.ch:</p>
      <p><strong>Vorname:</strong> ${contactData.firstName}</p>
      <p><strong>Nachname:</strong> ${contactData.lastName}</p>
      <p><strong>Telefon:</strong> ${contactData.phone}</p>
      <p><strong>E-Mail:</strong> ${contactData.email}</p>
    `,
      receiver: 'n.buchs@life-gate.ch',
    });

    this.sendEmailToClient(contactData); // Asynchrone Nachricht an den Kunden senden.

    return this.http.post(this.EMAIL_URL, adminEmailMessage).pipe(
      tap(() => this._loadingService.isLoading.next(false)),
      catchError((error) => throwError(() => error))
    );
  }

  private sendEmailToClient(contactData: any): void {
    const clientMessage = this.createEmailMessage({
      subject: 'Ihre Kontaktanfrage Maklermandat.ch',
      message: `
      <p>Guten Tag ${contactData.firstName} ${contactData.lastName}</p>
      <p>Ihre Kontaktdaten wurden erfolgreich übermittelt. Wir melden uns so bald wie möglich bei Ihnen.</p>
      <p>Freundliche Grüsse<br>Ihr Maklermandat.ch Team</p>
    `,
      receiver: contactData.email,
    });

    this.http.post(this.EMAIL_URL, clientMessage).subscribe({
      next: () => console.log('E-Mail an Kunde gesendet'),
      error: (error) => console.error('Fehler beim Senden der Kunden-E-Mail:', error),
    });
  }

  private createEmailMessage(params: { subject: string; message: string; receiver: string }): FormData {
    const formData = new FormData();
    formData.append('subject', params.subject);
    formData.append('message', params.message);
    formData.append('sender', 'noreply@maklermandat.ch');
    formData.append('receiver', params.receiver);
    return formData;
  }


}
