import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  userId: any;
  token: any;
  isAuthenticated = new BehaviorSubject(false);

  constructor() {
    this.userId = localStorage.getItem('user_id');
    this.token = localStorage.getItem('token');
  }

  authHeaders() {
    if (this.userId && this.token) {
      let headers = new HttpHeaders({
        'x-user-id': this.userId.toString(),
        'authorization': 'token ' + this.token
      });
      const options = {headers: headers};
      this.isAuthenticated.next(true);
      return options;
    } else {
      console.log('Your are not authorized!')
      this.isAuthenticated.next(false);
      return false;
    }
  }


}
