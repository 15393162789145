<img src="../../../assets/images/images/maklermandat-mobile.jpg" class="full-width mobile" alt="">
<div class="container">

  <!--  Einleitung -->
  <div class="row row align-items-center">
    <div class="col col-12 col-lg-6 order-lg-2 border-box pb-sm desktop">
      <img src="../../../assets/images/images/maklermandat-desktop.jpg" class="full-width" alt="">
    </div>
    <div class="col col-12 col-lg-6 order-lg-1 border-box">
      <h2 class="overline pb-sm">{{ address.company_name }}</h2>
      <h1 class="pb-sm">Maklermandat</h1>
      <h2 class="pb-sm">
        Durch das Maklermandat verhelfen wir Ihnen zu einem entspannten Umgang mit Ihren Versicherungsangelegenheiten.
        Sie haben nur noch eine Ansprechperson über alle Versicherungssparten- und Gesellschaften, die Sie bei uns im
        Mandat wünschen.
      </h2>
      <h2 class="pb-sm">
        Wir freuen uns Sie in Zukunft persönlich
        betreuen zu dürfen.
      </h2>
      <a [routerLink]="['maklermandat']" mat-button mat-flat-button color="primary">Maklermandat starten</a>
    </div>
  </div>

  <!--  Dienstleistungen -->
  <div class="row pt-xl">
    <h2 class="pb-sm">Von unseren Service-Dienstleistungen profitieren:</h2>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="105" height="115.02" viewBox="0 0 105 115.02">
            <g id="Gruppe_107" data-name="Gruppe 107" transform="translate(-145 -964.98)">
              <g id="security-on" transform="translate(215.305 1039.54)">
                <path id="Shape"
                      d="M32.91,6.25C26.112,6.25,20.9,4.3,16.5,0,12.1,4.3,6.888,6.25.09,6.25.09,17.447-2.217,33.486,16.5,40,35.217,33.486,32.91,17.447,32.91,6.25ZM15.606,26,10,20.344l2.51-2.532,3.1,3.124L21.49,15,24,17.532Z"
                      transform="translate(0)" fill="var(--primaryColor)"/>
              </g>
              <g id="Gruppe_101" data-name="Gruppe 101" transform="translate(-112 -99)">
                <g id="Gruppe_25" data-name="Gruppe 25" transform="translate(7.749 -379.311)">
                  <path id="Pfad_16" data-name="Pfad 16" d="M4234.722,1471.325v85.731H4287V1486.2l-12.388-14.879Z"
                        transform="translate(-3985.471 -9.359)" fill="var(--secondaryColor)"/>
                  <g id="Gruppe_8" data-name="Gruppe 8" transform="translate(256.751 1443.291)">
                    <path id="Pfad_10" data-name="Pfad 10" d="M4234.722,1471.325V1575.73H4311.2v-86.285l-18.119-18.12Z"
                          transform="translate(-4234.722 -1471.325)" fill="#fff"/>
                    <path id="Pfad_11" data-name="Pfad 11" d="M4264.945,1471.325V1490h18.728Z"
                          transform="translate(-4206.803 -1471.325)" fill="var(--primaryColor)"/>
                    <path id="Pfad_13" data-name="Pfad 13" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1484.084)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_54" data-name="Pfad 54" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1472.415)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_55" data-name="Pfad 55" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1460.747)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_56" data-name="Pfad 56" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1449.079)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_57" data-name="Pfad 57" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1437.41)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_58" data-name="Pfad 58" d="M4240.479,1515.973h40.994"
                          transform="translate(-4229.403 -1425.742)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                  </g>
                </g>
                <g id="security-on-2" data-name="security-on" transform="translate(327.305 1138.54)">
                  <path id="Shape-2" data-name="Shape"
                        d="M32.91,6.25C26.112,6.25,20.9,4.3,16.5,0,12.1,4.3,6.888,6.25.09,6.25.09,17.447-2.217,33.486,16.5,40,35.217,33.486,32.91,17.447,32.91,6.25ZM15.606,26,10,20.344l2.51-2.532,3.1,3.124L21.49,15,24,17.532Z"
                        transform="translate(0)" fill="var(--primaryColor)"/>
                </g>
              </g>
              <rect id="Rechteck_113" data-name="Rechteck 113" width="105" height="115" transform="translate(145 965)"
                    fill="none" opacity="0.295"/>
            </g>
          </svg>

        </div>
        <div class="card-content pt-sm">
          <h2>Betreuung</h2>
          <p>Sauber und effizient über alle Versicherungsverträge</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="108" height="115" viewBox="0 0 108 115">
            <g id="Gruppe_106" data-name="Gruppe 106" transform="translate(-527 -965)">
              <g id="Gruppe_19" data-name="Gruppe 19" transform="translate(-79.13 -94)">
                <path id="Pfad_44" data-name="Pfad 44" d="M4234.722,1471.325v96.313h70.55v-79.6l-16.715-16.716Z"
                      transform="translate(-3593.136 -394.098)" fill="var(--secondaryColor)"/>
                <path id="Pfad_45" data-name="Pfad 45" d="M4264.945,1471.325v17.227h17.276Z"
                      transform="translate(-3569.724 -394.098)" fill="var(--primaryColor)"/>
                <path id="Pfad_46" data-name="Pfad 46" d="M4234.722,1471.325v96.313h70.55v-79.6l-16.715-16.716Z"
                      transform="translate(-3628.592 -411.325)" fill="#fff"/>
                <path id="Pfad_47" data-name="Pfad 47" d="M4264.945,1471.325v17.227h17.276Z"
                      transform="translate(-3605.18 -411.325)" fill="var(--primaryColor)"/>
                <g id="Gruppe_17" data-name="Gruppe 17" transform="translate(705.592 1163.096) rotate(180)">
                  <path id="Pfad_48" data-name="Pfad 48" d="M0,0,6.784,6.783,0,13.566" transform="translate(13.958 0)"
                        fill="none" stroke="var(--primaryColor)" stroke-width="2"/>
                  <path id="Pfad_49" data-name="Pfad 49" d="M20.591,0H0" transform="translate(0 6.783)" fill="none"
                        stroke="var(--primaryColor)" stroke-width="2"/>
                </g>
                <g id="Gruppe_18" data-name="Gruppe 18" transform="translate(645.093 1132.925)">
                  <path id="Pfad_48-2" data-name="Pfad 48" d="M4701.767,1143.212l6.784,6.783-6.784,6.783"
                        transform="translate(-4687.808 -1143.212)" fill="none" stroke="var(--primaryColor)"
                        stroke-width="2"/>
                  <path id="Pfad_49-2" data-name="Pfad 49" d="M4614.886,1148.167h-20.591"
                        transform="translate(-4594.295 -1141.384)" fill="none" stroke="var(--primaryColor)"
                        stroke-width="2"/>
                </g>
              </g>
              <rect id="Rechteck_114" data-name="Rechteck 114" width="108" height="115" transform="translate(527 965)"
                    fill="none" opacity="0.295"/>
            </g>
          </svg>

        </div>
        <div class="card-content pt-sm">
          <h2>Prüfung</h2>
          <p>Periodischer Vergleich des Marktes</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="95" height="115"
               viewBox="0 0 95 115">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rechteck_30" data-name="Rechteck 30" width="90.706" height="95.201"
                      fill="var(--primaryColor)"/>
              </clipPath>
              <clipPath id="clip-path-2">
                <rect id="Rechteck_29" data-name="Rechteck 29" width="82.293" height="56.135"
                      fill="var(--secondaryColor)"/>
              </clipPath>
            </defs>
            <g id="Gruppe_105" data-name="Gruppe 105" transform="translate(-908 -965)">
              <g id="Gruppe_55" data-name="Gruppe 55" transform="translate(762.18 -99)">
                <rect id="Rechteck_60" data-name="Rechteck 60" width="73.285" height="87.855" rx="36"
                      transform="matrix(1, 0.017, -0.017, 1, 163.189, 1081.787)" fill="#fff"/>
                <g id="Gruppe_53" data-name="Gruppe 53" transform="translate(146.82 1064)">
                  <g id="Gruppe_22" data-name="Gruppe 22" clip-path="url(#clip-path)">
                    <path id="Pfad_51" data-name="Pfad 51"
                          d="M90.6,31.54C76.721-19.1-4.863-5.806,4.666,50.323a1.169,1.169,0,0,1-.7,1.213c-4,1.956-4.443,7.271-3.611,11.208,2.3,7.124,4.585,19.1,14.166,16.705.9-.288,1.306.415,1.771.978a29.673,29.673,0,0,0,8.222,7.408c5.605,3.155,24.174,12.917,27.778,3.094.724-2.568-1.1-5.508-3.59-5.67-3.406-.222-7.943-1.458-9.966,2.34-5.676,1.261-14.6-4.8-18.321-10.655-5.255-8.279-4.794-19.425-10.946-27.206C2.534,7.311,57.38-10.641,80.177,21.2c7.327,8.754,5.432,20.763,6.05,31.5-.024,2.812,4.2,2.739,4.356.063.291-7.062-.038-14.158.017-21.232"
                          transform="translate(0 0)" fill="var(--primaryColor)"/>
                  </g>
                </g>
                <g id="Gruppe_54" data-name="Gruppe 54" transform="translate(158.184 1073.37)">
                  <g id="Gruppe_20" data-name="Gruppe 20" clip-path="url(#clip-path-2)">
                    <path id="Pfad_50" data-name="Pfad 50"
                          d="M24.714,26.956c14.47,14.451,38.034,20.791,57.278,17.481,1.42-14.518-1.954-29.326-15.292-37.2C31.987-13.112-9.124,11.732,1.792,53.277c1.085,4.129,4.07,3.1,5.174,1.168.936-1.638.819-6.341.651-9.439-.454-8.363,8.631-19.206,17.1-18.05"
                          transform="translate(0 0)" fill="var(--secondaryColor)"/>
                  </g>
                </g>
              </g>
              <rect id="Rechteck_115" data-name="Rechteck 115" width="95" height="115" transform="translate(908 965)"
                    fill="none" opacity="0.295"/>
            </g>
          </svg>

        </div>
        <div class="card-content pt-sm">
          <h2>Persönlich</h2>
          <p>Betreut durch Ihren Kundenberater oder die Hotline</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="104" height="115" viewBox="0 0 104 115">
            <g id="Gruppe_103" data-name="Gruppe 103" transform="translate(-145 -1344)">
              <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(-104.251 -96.291)">
                <path id="Pfad_16" data-name="Pfad 16" d="M4234.722,1471.325v85.731H4287V1486.2l-12.388-14.879Z"
                      transform="translate(-3985.471 -9.359)" fill="var(--secondaryColor)"/>
                <g id="Gruppe_8" data-name="Gruppe 8" transform="translate(256.751 1443.291)">
                  <path id="Pfad_10" data-name="Pfad 10" d="M4234.722,1471.325V1575.73H4311.2v-86.285l-18.119-18.12Z"
                        transform="translate(-4234.722 -1471.325)" fill="#fff"/>
                  <path id="Pfad_11" data-name="Pfad 11" d="M4264.945,1471.325V1490h18.728Z"
                        transform="translate(-4206.803 -1471.325)" fill="var(--primaryColor)"/>
                  <path id="Pfad_12" data-name="Pfad 12" d="M4240.479,1509.908h31.384"
                        transform="translate(-4229.403 -1435.686)" fill="none" stroke="var(--primaryColor)"
                        stroke-width="2"/>
                  <path id="Pfad_13" data-name="Pfad 13" d="M4240.479,1515.973h51.314"
                        transform="translate(-4229.403 -1430.084)" fill="none" stroke="var(--primaryColor)"
                        stroke-width="2"/>
                </g>
                <g id="Gruppe_7" data-name="Gruppe 7" transform="translate(339.572 1440.311) rotate(30)">
                  <path id="Differenzmenge_1" data-name="Differenzmenge 1"
                        d="M15.272,13.09H0V5A5.006,5.006,0,0,1,5,0h5.272a5.006,5.006,0,0,1,5,5v8.088Z"
                        transform="translate(0.001 0)" fill="var(--primaryColor)"/>
                  <rect id="Rechteck_14" data-name="Rechteck 14" width="15.271" height="63.266"
                        transform="translate(0.001 6.545)" fill="var(--secondaryColor)"/>
                  <path id="Pfad_17" data-name="Pfad 17" d="M0,0H15.27l-7.6,15.22Z" transform="translate(0.002 69.811)"
                        fill="var(--primaryColor)"/>
                </g>
              </g>
              <rect id="Rechteck_116" data-name="Rechteck 116" width="104" height="115" transform="translate(145 1344)"
                    fill="none" opacity="0.295"/>
            </g>
          </svg>
        </div>
        <div class="card-content pt-sm">
          <h2>Koordination</h2>
          <p>Kommunikation mit den einzelnen Versicherungsgesellschaften</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="96" height="115"
               viewBox="0 0 96 115">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rechteck_32" data-name="Rechteck 32" width="80.17" height="32.057"
                      fill="var(--secondaryColor)"/>
              </clipPath>
            </defs>
            <g id="Gruppe_102" data-name="Gruppe 102" transform="translate(-527 -1344)">
              <g id="Gruppe_32" data-name="Gruppe 32" transform="translate(-99.054 -112)">
                <g id="Gruppe_29" data-name="Gruppe 29" transform="translate(0 1.075)">
                  <rect id="Rechteck_31" data-name="Rechteck 31" width="39.413" height="34.776"
                        transform="translate(667.784 1482.181)" fill="var(--primaryColor)"/>
                  <g id="security-on" transform="translate(654.791 1459.02)">
                    <path id="Shape"
                          d="M65.221,12.386C51.748,12.386,41.427,8.521,32.7,0,23.972,8.521,13.651,12.386.179,12.386c0,22.19-4.573,53.977,32.52,66.887C69.794,66.363,65.221,34.576,65.221,12.386ZM30.928,51.527,19.818,40.318,24.792,35.3l6.135,6.191L42.59,29.727l4.974,5.018Z"
                          transform="translate(0)" fill="#fff"/>
                  </g>
                </g>
                <g id="Gruppe_31" data-name="Gruppe 31" transform="translate(637 1523.339)">
                  <g id="Gruppe_30" data-name="Gruppe 30" clip-path="url(#clip-path)">
                    <path id="Pfad_59" data-name="Pfad 59"
                          d="M0,12.5c1.184-.7,2.292-1.364,3.4-2.018C8.447,7.517,13.5,4.569,18.53,1.571A10.908,10.908,0,0,1,24.306,0c6.7.022,13.4,0,20.107.01a5.5,5.5,0,0,1,5.523,3.758,5.468,5.468,0,0,1-5.4,7.134c-5.266.03-10.533.007-15.8.008-.338,0-1.882-.049-1.988.725-.089.677.711.776,1.433,1.087,1.447.622,10.167,4.076,13.247,5.381,3.352,1.42,6.187.749,9.233-1.124,7.551-4.643,13.413-7.616,20.649-12.36a7.017,7.017,0,0,1,6.045-.368,4.217,4.217,0,0,1,2.789,3.712,4.081,4.081,0,0,1-1.849,4.012C76,13.5,58.008,26.854,51.352,31.07a9,9,0,0,1-5.018,1.444c-9.575-.046-19.151-.022-28.726-.07a10.146,10.146,0,0,0-6.267,1.692C7.576,27.616,3.793,19.071,0,12.5"
                          transform="translate(0 0)" fill="var(--secondaryColor)"/>
                  </g>
                </g>
                <rect id="Rechteck_33" data-name="Rechteck 33" width="10" height="31"
                      transform="translate(626.054 1537.077) rotate(-30)" fill="var(--primaryColor)"/>
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.5" cy="1.5" r="1.5"
                        transform="translate(643.054 1557)" fill="#fff"/>
              </g>
              <rect id="Rechteck_117" data-name="Rechteck 117" width="96" height="115" transform="translate(527 1344)"
                    fill="none"/>
            </g>
          </svg>

        </div>
        <div class="card-content pt-sm">
          <h2>Unterstützung</h2>
          <p>z.Bsp. im Schadenfall</p>
        </div>
      </div>
    </div>

    <div class="col col-12 col-md-6 col-lg-4 border-box">
      <div class="card card-square">
        <div class="card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="115" viewBox="0 0 80 115">
            <g id="Gruppe_104" data-name="Gruppe 104" transform="translate(-909 -1344)">
              <g id="Gruppe_51" data-name="Gruppe 51" transform="translate(-113 -115)">
                <rect id="Rechteck_59" data-name="Rechteck 59" width="11" height="17" transform="translate(1057 1462)"
                      fill="var(--primaryColor)"/>
                <path id="Pfad_87" data-name="Pfad 87" d="M0,10,36,0V49H0Z" transform="translate(1022 1518)"
                      fill="var(--secondaryColor)"/>
                <path id="Pfad_86" data-name="Pfad 86" d="M0,0,26,10V72H0Z" transform="translate(1076 1495)"
                      fill="var(--secondaryColor)"/>
                <path id="Pfad_78" data-name="Pfad 78" d="M0,17.219,53.132-2,52,103.464H0Z"
                      transform="translate(1036 1463.536)" fill="#fff"/>
                <g id="Gruppe_50" data-name="Gruppe 50">
                  <g id="Gruppe_48" data-name="Gruppe 48" transform="translate(-0.911 -2.171)">
                    <path id="Pfad_80" data-name="Pfad 80" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 -20.973)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_81" data-name="Pfad 81" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 -9.305)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_82" data-name="Pfad 82" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 2.364)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_83" data-name="Pfad 83" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 14.032)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_84" data-name="Pfad 84" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 25.7)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                  </g>
                  <g id="Gruppe_49" data-name="Gruppe 49" transform="translate(17.089 -2.171)">
                    <path id="Pfad_80-2" data-name="Pfad 80" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 -20.973)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_81-2" data-name="Pfad 81" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 -9.305)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_82-2" data-name="Pfad 82" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 2.364)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_83-2" data-name="Pfad 83" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 14.032)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                    <path id="Pfad_84-2" data-name="Pfad 84" d="M4240.479,1515.973h11.13"
                          transform="translate(-3191.568 25.7)" fill="none" stroke="var(--primaryColor)"
                          stroke-width="2"/>
                  </g>
                </g>
                <rect id="Rechteck_58" data-name="Rechteck 58" width="11" height="15" transform="translate(1057 1552)"
                      fill="var(--primaryColor)"/>
              </g>
              <rect id="Rechteck_118" data-name="Rechteck 118" width="80" height="115" transform="translate(909 1344)"
                    fill="none" opacity="0.295"/>
            </g>
          </svg>

        </div>
        <div class="card-content pt-sm">
          <h2>All in One</h2>
          <p>Eine Ansprechperson über alle Gesellschaften</p>
        </div>
      </div>
    </div>

  </div>

  <div class="row pt-xl pb-xl">
    <div class="col col-12 col-lg-6 pb-sm">
      <h1>Bei weiteren Fragen helfen wir gerne weiter.</h1>
    </div>
    <div class="col col-12 col-lg-6">
      Kontaktieren Sie unseren per Telefon unter <a href="tel:{{ address.phone }}">{{ address.phone }}</a> oder per E-Mail unter
      <a href="mailto:{{ address.email }}">{{ address.email }}</a>
      Wir sind Montag bis Freitag von 8 Uhr bis 18 Uhr für Sie erreichbar.
    </div>
  </div>

  <div class="row pt-sm pb-sm">
    <div class="col col-12 pb-sm">
      <a href="impressum" [routerLink]="['impressum']" style="text-decoration: none; margin-right: 12px;">Impressum</a>
      <a href="datenschutz" [routerLink]="['datenschutz']" style="text-decoration: none; margin-right: 12px;">Datenschutz</a>
      <a href="disclaimer" [routerLink]="['disclaimer']" style="text-decoration: none; margin-right: 12px;">Disclaimer</a>
      <a href="agb" [routerLink]="['agb']" style="text-decoration: none;">AGB's</a>
    </div>
  </div>
</div>
