<ng-container *ngIf="!preSavedData">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Personalien</h1>
      <h2 class="col-lg-9 pb-sm">
        Die nachfolgenden Felder sind Pflichtfelder. Die Daten werden im Maklermandat, welches sie am Schluss
        unterzeichnen verwendet.
      </h2>
    </div>
  </div>

  <form [formGroup]="addressFormGroup">
    <div class="row">
      <div class="col-md">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Vorname</mat-label>
          <input matInput placeholder="Vorname" formControlName="firstName" required>
        </mat-form-field>
      </div>
      <div class="col-md">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Nachname" formControlName="lastName" required>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Strasse Nr.</mat-label>
          <input matInput placeholder="Strasse" formControlName="street" required>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>PLZ</mat-label>
          <input matInput placeholder="PLZ" formControlName="postCode" required>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Ort</mat-label>
          <input matInput placeholder="Ort" formControlName="city" required>
        </mat-form-field>
      </div>
    </div>

    <div class="row pt-md">
      <div class="col">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>E-Mail</mat-label>
          <input matInput placeholder="E-Mail" formControlName="email" required>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Telefon</mat-label>
          <input matInput placeholder="Telefon" formControlName="phone" required>
        </mat-form-field>
      </div>
    </div>

  </form>
</ng-container>

@if(preSavedData){
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Ihre Personalien</h1>
      <h2 class="col-lg-9 pb-sm">
        {{preSavedData.first_name }} {{preSavedData.last_name }}<br>
        {{preSavedData.street }}<br>
        {{preSavedData.post_code }} {{preSavedData.city }}<br><br>
        {{preSavedData.email }}<br>
        {{preSavedData.phone }}<br>
      </h2>
    </div>
  </div>
}
