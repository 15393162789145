import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "../../shared/services/api.service";
import {Router} from "@angular/router";
import {AuthorizationService} from "../../shared/services/authorization.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatButton,
    MatLabel
  ]
})
export class LoginComponent {

  email = '';
  password = '';

  constructor(private http: HttpClient,
              private _apiService: ApiService,
              private _authService: AuthorizationService,
              private _router: Router,
              private _snackBar: MatSnackBar) {
  }


  login() {

    const credentials = this.email + ':' + this.password;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(credentials)
    });
    const options = {headers: headers};

    let formData = {
      "username": this.email,
      "password": this.password
    }

    this._apiService.login(formData, options).subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user_id', response.user_id);
        this._authService.userId = response.user_id;
        this._authService.token = response.token;
        this._router.navigate(['/account']);
        this._snackBar.open('erfolgreich angemeldet', '', {
          duration: 4000,
        });
      },
      error: (error: any) => {
        this._snackBar.open('Login nicht erfolgreich', '', {
          duration: 4000,
        });
        console.error('There was an error!', error);
      }
    });

  }

}
