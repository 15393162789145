import {Component, OnInit,} from '@angular/core';
import {MatDialog,} from "@angular/material/dialog";
import {MandatFormService} from "../../shared/services/mandat-form.service";
import {PdfService} from "../../shared/services/pdf.service";
import {SignatureDialogComponent} from "../../shared/signature-dialog/signature-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatIcon} from "@angular/material/icon";
import {MatFabButton} from "@angular/material/button";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatFabButton,
    NgIf
  ]
})
export class SignatureComponent implements OnInit {

  img: any;
  client: any;
  mobiPdf = false;
  currentDate = new Date();
  currentDateString = new Intl.DateTimeFormat('de-DE', {
    day: 'numeric', month: 'numeric', year: 'numeric'
  }).format(this.currentDate);
  preSavedData: any;


  constructor(public dialog: MatDialog,
              private _mandatFormService: MandatFormService,
              private _pdfService: PdfService,
              private _snackBar: MatSnackBar,) {
  }

  ngOnInit() {
    if (this._mandatFormService.personalInformationObservable) {
      this._mandatFormService.personalInformationObservable.subscribe((value: any) => {
        this.client = value;
      });
    }
    this.checkIfPrefilled();
  }

  checkIfPrefilled() {
    this._mandatFormService.preSavedData.subscribe((response: any) => {
      this.preSavedData = response.police;
      console.log(this.preSavedData);
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SignatureDialogComponent, {
      data: {img: this.img},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.img = result;
      this.savePdf();
    });
  }

  savePdf() {
    this._pdfService.createPdf(this.img, this.client, this.currentDateString);
    this._pdfService.createPdfAGB(this.img, this.currentDateString);
    this.saveMobiPdf()
  }

  saveMobiPdf() {
    if (this._mandatFormService.contractList) {
      const found = this._mandatFormService.contractList.some(
        (innerArray: any) => innerArray.some((obj: any) => obj.company === 'Die Mobiliar / MobiLife')
      );
      if (found) {
        this.mobiPdf = true;
        this._pdfService.createPdfMobiliar(this.img, this.currentDateString);
      }
    }
    if (this.preSavedData) {
      const found = this.preSavedData.find(
        (obj: any) => obj.company === 'Die Mobiliar / MobiLife');
      if (found) {
        this.mobiPdf = true;
        this._pdfService.createPdfMobiliar(this.img, this.currentDateString);
      }
    }
  }

  viewPdf(int: any) {
    if (int === 1) {
      this._pdfService.viewPdf(this._pdfService.pdfToOpen);
    }
    if (int === 2) {
      this._pdfService.viewPdf(this._pdfService.mobiPdfToOpen);
    }
    if (int === 3) {
      this._pdfService.viewPdf(this._pdfService.agbPdfToOpen);
    }
    //this._pdfService.viewPdf(xx);
    //this._pdfService.viewPdf();
  }


}
