import {Component} from '@angular/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  standalone: true,
  imports: []
})
export class DisclaimerComponent {

}
