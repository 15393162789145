<ng-container *ngIf="!preSavedData || this.preSavedData.length == 0">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Versicherungsverträge</h1>
      <h2 class="col-lg-9 pb-sm">
        Wählen Sie zunächst Ihren Versicherungsanbieter und dann die Art(en)
        der Versicherung(en), die Sie bei ihm abgeschlossen haben. Um weitere Versicherungsgesellschaften hinzuzufügen,
        klicken Sie auf den «Versicherungsgesellschaft hinzufügen» Button.
      </h2>
    </div>
  </div>

  <ng-container *ngIf="clientsContractList.length">
    <h3 class="pb-sm">Ihre Versicherungsverträge:</h3>
    <ng-container *ngFor="let selection of clientsContractList;  let index = index">
      <div class="row">
        <div class="col">
          <div class="card mb-sm">
            <button mat-icon-button
                    aria-label="Delete Button"
                    (click)="deleteFinal(index)">
              <mat-icon class="material-symbols-outlined">cancel_outline</mat-icon>
            </button>
            <div class="row pb-xsm" *ngFor="let items of selection; let first = first;">
              <div class="row " *ngIf="first">
                <h2 class="pb-xsm">{{ selection[0].company }}</h2>
              </div>
              <div class="col-md">
                {{ items.contract }}
              </div>
              <div class="col-md" *ngIf="items.police != ''">
                Police: {{ items.police }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="clientsContractList.length">
    <h3 class="col-lg-9 pb-sm pt-sm border-top no-mobile-border full-width">
      Wählen Sie einen weiteren Versicherungsanbieter aus.
    </h3>
  </ng-container>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Versicherungsgesellschaft</mat-label>
        <mat-select [(value)]="selectedCompany"
                    (selectionChange)="onCompanySelectChange()"
                    [formControl]="companyCtrl"
                    placeholder="Versicherungsgesellschaft finden..."
                    #singleSelect>

          <mat-option>
            <ngx-mat-select-search [formControl]="companyFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option>Eingabe löschen</mat-option>
          <mat-option *ngFor="let company of filteredCompany | async" [value]="company">
            {{company.name}}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix>expand_more</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="selectedCompany">
    <div class="row pb-md">
      <mat-chip-listbox [(ngModel)]="selectedContracts" selectable multiple aria-label="Contract selection">
        <mat-chip-option *ngFor="let contract of contracts"
                         (click)="onContractSelectChange(contract.name)">
          {{ contract.name }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <form [formGroup]="policeFormGroup">
      <div class="row pt-sm no-mobile-padding border-top no-mobile-border" *ngIf="selectedContracts.length > 0">
        <div class="col-lg-9 pb-sm">
          <h1 class="pb-sm">Policen</h1>
          <h2>
            Geben Sie die Policen Nummern für die ausgewählten Versicherungsarten an.
            Sollten Sie diese nicht zur Hand haben, können Sie diesn Schritt auch überspringen.
          </h2>
        </div>
      </div>
      <div class="row pb-sm no-mobile-padding " *ngFor="let option of selectionList; let index = index">
        <div class="col-md col-contract">{{ option.contract }}</div>
        <div class="col-md">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Policen Nr.</mat-label>
            <input matInput
                   placeholder="Policen Nr."
                   formControlName="police"
                   (change)="onPoliceChange($event, index)">
          </mat-form-field>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="selectedCompany && selectionList.length">
    <div class="row pb-md pt-sm border-top no-mobile-border">
      <div class="col">
        <h2 class="pb-sm">Um weitere Versicherungsgesellschaften hinzuzufügen,
          klicken Sie auf den «Versicherungsgesellschaft hinzufügen» Button.
        </h2>
      </div>
      <div class="col">
        <button mat-button
                mat-flat-button
                color="primary"
                class="full-width"
                (click)="addNewContract()">
          + Versicherung hinzufügen
        </button>
      </div>
    </div>


  </ng-container>
</ng-container>

<ng-container *ngIf="this.preSavedData">
  <ng-container *ngIf="this.preSavedData.length > 0">
    <div class="row pt-xl">
      <div class="col">
        <h1 class="pb-sm">Ihre Versicherungsverträge</h1>
        <h2 class="col-lg-9 pb-xsm" *ngFor="let police of preSavedData">
          {{police.company}}<br>
          {{police.contract}}<br>
          <span *ngIf="police.police">{{police.police}}</span>
        </h2>
      </div>
    </div>
  </ng-container>
</ng-container>

